import React, { useEffect, useState } from 'react';
import Button from '../../../UI/Button';
import Select from '../../../UI/Select';
import DisplayFieldProps from './Models/DisplayFieldProps';
import Dropdown from '../../../UI/Dropdown';

const SelectListDisplayField = (props: DisplayFieldProps) => {
    const [showAdvanced, setShowAdvanced] = useState(props.showAdvanced);

    useEffect(() => {
        setShowAdvanced(props.showAdvanced);
    }, [props.showAdvanced]);

    const options = [
        {
            label: 'Edit',
            onClick: () => props.onEdit(props.field)
        },
        {
            label: 'Delete',
            onClick: () => props.onDelete(props.field)
        }
    ];

    return (<><div className="field-wrapper">
        <div className="row justify-content-between align-items-center">
            <div className="column">
                <Select id={props.field.id} disabled={true} required={props.field.required} label={props.field.label || 'Unnamed Radio Button Field'}>
                    {(props.field.options && props.field.options.map((o: any, index: number) => {
                        return (<option key={index} value={o.value}>{o.label}</option>);
                    })) || []}
                </Select>
            </div>
            {!props.hideButtons && <div className="column-auto margin-top-5">
                <Dropdown id={`dropdown-${props.field.id}`} options={options} />
            </div>}
        </div>
        <div className="margin-vertical-3">
            <i className="ri-dropdown-list margin-start-1"></i> Select List
        </div>
        <div className="margin-vertical-3">
            <Button id="toggle-advanced-button"
                className="flat small"
                text={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
                icon={showAdvanced ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}
                iconLocation="end" onClick={() => setShowAdvanced(!showAdvanced)} />

        </div>
        {showAdvanced && <div className="advanced-content">
            <label>Name:</label>{props.field.name}
        </div>}
    </div>
    </>);
};

export default SelectListDisplayField;