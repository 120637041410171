import { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import CurrentPlan from "../components/UI/CurrentPlan";
import Loader from "../components/UI/Loader";
import NewPlan from "../components/UI/NewPlan";
import { FREE_PLAN_TYPE_ID, STANDARD_PLAN_TYPE_ID, TRIAL_PLAN_TYPE_ID } from "../constants/planTypes";
import { OrganizationPlan } from "../models/serviceModels/OrganizationPlan";
import { getItem, setItem } from "../services/cacheService";
import { searchUsers } from "../services/userService";
import getclassNames from "../utils/getClassNames";
import { formatCurrency } from "../utils/stringHelpers";
import { OrganizationContext } from "../App";

const ChangePlan = () => {
    const [plan, setPlan] = useState<number>();
    const [currentPlan, setCurrentPlan] = useState<OrganizationPlan>();
    const [users, setUsers] = useState<number>(0);
    const [planCost, setPlanCost] = useState<number>(0);
    const [apiErrors, setApiErrors] = useState<any>();
    const [loading, setLoading] = useState(false);
    const organization = useContext(OrganizationContext);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Manage Plan"
    }, []);

    const newPlan = useMemo(() => {
        return getItem<{ users: number, plan: number }>('new_plan')
    }, []);

    useEffect(() => {
        if (!newPlan) return;

        setPlan(newPlan.plan);
    }, [newPlan]);

    useEffect(() => {
        if (plan === FREE_PLAN_TYPE_ID) {
            setPlanCost(0);
        } else if (plan === STANDARD_PLAN_TYPE_ID) {
            setPlanCost(15);
        } else {
            setPlanCost(0);
        }
    }, [plan]);

    useEffect(() => {
        setLoading(true);

        searchUsers('', 0, 10000).then((users) => {
            setUsers(users.data.filter(d => !d.deactivated).length);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const onPlanLoaded = (plan?: OrganizationPlan) => {
        setCurrentPlan(plan);
        if (plan && !newPlan) {
            setPlan(plan.planTypeId);
        }
    };

    const handleNext = () => {
        if (!plan) return;

        setItem('new_plan', { plan, users });
        navigate('/admin/billing/plan/payment-details');
    };

    const getPlans = () => {
        let standardButtonText = 'Select Standard';
        let standardPlanType = STANDARD_PLAN_TYPE_ID;
        let showPriceCaveat = false;
        let maxUsersCaveatText = '*';
        if (currentPlan?.planTypeId === FREE_PLAN_TYPE_ID && !organization.value?.hadTrial) {
            standardButtonText = 'Start Free Trial';
            standardPlanType = TRIAL_PLAN_TYPE_ID;
            showPriceCaveat = true;
            maxUsersCaveatText = '**';
        }

        return (<section id="main-content" tabIndex={-1}>
            <h3>Plans</h3>
            <div className="row justify-content-center">
                <div className="column-medium-4">
                    <div className={getclassNames('card', plan === FREE_PLAN_TYPE_ID ? 'selected' : '')}>
                        <div className="card-container large">
                            <div>
                                <h4><strong>Free</strong></h4>
                                <div>Max 3 Years Free</div>
                                <hr />
                                <ul>
                                    <li>3 Users</li>
                                    <li>250 Particiants</li>
                                    <li>5 Custom Forms</li>
                                    <li>250 Form Submissions per Month</li>
                                    <li>250 Custom Case Plans</li>
                                    <li>100MB Form Data per User</li>
                                    <li>10 Basic Reports per Day</li>
                                    <li>Email Support</li>
                                    <li>Self-Help Guides</li>
                                </ul>
                            </div>
                            <div>
                                <div className="row justify-content-end align-items-center">
                                    {currentPlan?.planTypeId === FREE_PLAN_TYPE_ID && <div className="column-auto">
                                        <i>Current Plan</i>
                                    </div>}
                                    <div className="column-auto">
                                        <Button id="select-free-button" 
                                            text="Select Free"
                                            className="small" 
                                            onClick={() => setPlan(FREE_PLAN_TYPE_ID)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column-medium-4">
                    <div className={getclassNames('card', plan === STANDARD_PLAN_TYPE_ID ? 'selected' : '')}>
                        <div className="card-container large">
                            <div>
                                <h4><strong>Standard</strong></h4>
                                <div>$15 user seat/month{showPriceCaveat && <>*</>}</div>
                                <hr />
                                <ul>
                                    <li>Unlimited Users{maxUsersCaveatText}</li>
                                    <li>Unlimited Participantss</li>
                                    <li>Unlimited Custom Forms</li>
                                    <li>Unlimited Form Submissions per Month</li>
                                    <li>Unlimited Custom Case Plans</li>
                                    <li className="success-message">File Uploads</li>
                                    <li>1GB Form Data per User</li>
                                    <li className="success-message">3GB File Data per User</li>
                                    <li>Unlimited Basic Reports</li>
                                    <li className="success-message">Unlimited Advanced Reports</li>
                                    <li>Email Support</li>
                                    <li>Self-Help Guides</li>
                                    <li className="success-message">8 Hours Free 1-on-1 Onboarding</li>
                                </ul>
                            </div>                            
                            <div className="row justify-content-center margin-vertical-2">
                                <div className="column-large-10">
                                    <div className="text-sub margin-top-3">
                                        {showPriceCaveat && <div>*Billing begins after deciding to sign up when 30 day trial ends</div>}
                                        <div>{maxUsersCaveatText}No limit on the number of users that can be created.  Costs $15 per month per user created.</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row justify-content-end align-items-center">
                                    {currentPlan?.planTypeId === STANDARD_PLAN_TYPE_ID && <div className="column-auto">
                                        <i>Current Plan</i>
                                    </div>}
                                    <div className="column-auto">
                                        <Button id="select-standard-button" 
                                            text={standardButtonText}
                                            className="small" 
                                            onClick={() => setPlan(standardPlanType)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
    };

    const getMainContent = () => {
        if (loading) {
            return <Loader />
        }

        return (<>
            <BackButton />
            <ApiErrorMessages error={apiErrors} />
            <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <div className="row">
                        <div className="column-medium-4">
                            <CurrentPlan onLoad={onPlanLoaded} />
                        </div>
                        <div className="column-medium-4">
                            <NewPlan />
                        </div>
                    </div>
                    <div className="row margin-top-3">
                        <div className="column">
                            <hr />
                            {getPlans()}
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-end margin-top-5">
                        <div className="column-auto">
                            {users} Active User(s) x {formatCurrency(planCost)}
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <h4>Subtotal: {formatCurrency(planCost * users)}</h4>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <div className="row justify-content-end">
                                <div className="column-auto">
                                    <Button id="checkout-button" 
                                        text="Next" 
                                        className="action"
                                        disabled={!plan || users <= 0 || (plan === FREE_PLAN_TYPE_ID && users > 3) || currentPlan?.planTypeId === plan}
                                        onClick={handleNext} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="column-auto">
                                    {plan === FREE_PLAN_TYPE_ID && users > 3 && <div><small>Please lower your total active users to 3 or less to continue</small></div>}
                                    {(!plan || currentPlan?.planTypeId === plan) && <div><small>Please select a different plan to continue</small></div>}
                                    {users <= 0 && <div><small>Please active atleast one user to continue</small></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    };

    return getMainContent();
};

export default ChangePlan;