import { useContext, useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Loader from "../components/UI/Loader";
import Organization from "../models/serviceModels/Organization";
import { getOrganization } from "../services/organizationService";
import { OrganizationContext } from "../App";
import { format } from "date-fns";

const ViewOrganization = () => {
    const organization = useContext(OrganizationContext);

    useEffect(() => {
        document.title = "Manage Organization"
    }, []);

    return (<section id="main-content" tabIndex={-1}>
        {organization.loading && <Loader />}
        {!organization.loading && organization.value && <>
            <div className="row justify-content-between align-items-center">
                <div className="column-auto">
                    <h1>Manage Organization</h1>
                </div>
                <div className="column-auto">
                    <Button id="edit-organization-button" className="secondary" text="Edit" href="/admin/organization/edit" />
                </div>
            </div>
            {organization.value?.planEndDate && <div className="row">
                <div className="column-auto">
                    Plan End Date: {format(new Date(organization.value!.planEndDate), 'MM/dd/yyyy')}
                </div>
            </div>}
            <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <ApiErrorMessages error={organization.error} />
                    {organization && <>
                        <div className="row">
                            <div className="column">
                                <div className="row margin-vertical-4">
                                    <div className="column margin-top-5">
                                        <label>Name</label>
                                        <div>{organization.value.name}</div>
                                    </div>
                                </div>
                                {organization.value.url && <div className="row margin-vertical-4">
                                    <div className="column margin-top-5">
                                        <label>URL</label>
                                        <div>{organization.value.url}</div>
                                    </div>
                                </div>}
                                <div className="row margin-vertical-4">
                                    <div className="column margin-top-5">
                                        <label>Phone Number</label>
                                        <div>{organization.value.phoneNumber}</div>
                                    </div>
                                    <div className="column margin-top-5">
                                        <label>Email</label>
                                        <div>{organization.value.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="row margin-vertical-4">
                                    <div className="column margin-top-5">
                                        <label>Address</label>
                                        <div>{organization.value.addressLine1}</div>
                                        {organization.value.addressLine2 && <div>{organization.value.addressLine2}</div>}
                                        <div>{organization.value.city}, {organization.value.state} {organization.value.zipCode}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>}
    </section>
    )
};

export default ViewOrganization;
