import { useContext, useEffect, useState } from "react";
import Button from "../components/UI/Button";
import { FREE_PLAN_TYPE_ID, TRIAL_PLAN_TYPE_ID } from "../constants/planTypes";
import getclassNames from "../utils/getClassNames";
import { createOrganizationPlan } from "../services/organizationService";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import ErrorMessages from "../components/UI/ErrorMessages";
import { MeContext, OrganizationContext } from "../App";

const SelectPlan = () => {
    const me = useContext(MeContext);
    const organization = useContext(OrganizationContext);
    const [plan, setPlan] = useState<number>();
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (!me.user) {
            return;
        }

        if (me.user.organizationPlanTypeId) {
            document.location.href = '/';
        }
    }, [me.loading, me.user])

    const handleOnConfirm = async () => {
        if (!plan) {
            console.warn('No plan selected');
            setErrors(['A plan selection is required']);
            return;
        }

        setApiError(undefined);
        setErrors([]);

        setSubmitting(true);

        try {
            await createOrganizationPlan({
                planTypeId: plan
            });

            me.refresh();
            organization.refresh();

            // doing a hard refresh to force things to reload that should reload
            document.location.href = '/';
        } catch(e) {
            console.error(e);
            setApiError(e);
        } finally {
            setSubmitting(false);
        }
    };

    return <>
        <div className="section" tabIndex={-1}>
            <h1>Select a Plan</h1>
            <div className="row justify-content-center">
                <div className="column-medium-10">
                    <ApiErrorMessages error={apiError} />
                    <ErrorMessages messages={errors} />
                    <p>In order to use countbubble you must select a plan.
                        We offer a Free Plan or a Standard Plan that automatically comes with a risk free 14 day free trial.
                        If you aren't sure what you need, no worries you can always change later.</p>
                    <div className="row justify-content-center">
                        <div className="column-large-5 margin-vertical-2">
                            <div className={getclassNames('card', plan === FREE_PLAN_TYPE_ID ? 'selected' : '')}>
                                <div className="card-container large">
                                    <div>
                                        <h2>Free</h2>
                                        <div>Max 3 Years Free</div>
                                        <hr />
                                        <ul>
                                            <li>2 Users</li>
                                            <li>250 Particiants</li>
                                            <li>5 Custom Forms</li>
                                            <li>250 Form Submissions per Month</li>
                                            <li>250 Custom Case Plans</li>
                                            <li>100MB Form Data per User</li>
                                            <li>10 Basic Reports per Day</li>
                                            <li>Email Support</li>
                                            <li>Self-Help Guides</li>
                                        </ul>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="column-auto">
                                            <Button id="select-free-plan"
                                                text="Select Free"
                                                onClick={() => setPlan(FREE_PLAN_TYPE_ID)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column-large-5 margin-vertical-2">
                            <div className={getclassNames('card', plan === TRIAL_PLAN_TYPE_ID ? 'selected' : '')}>
                                <div className="card-container large">
                                    <div>
                                        <h2>Standard</h2>
                                        <div>$15 user seat/month</div>
                                        <hr />
                                        <ul>
                                            <li>Unlimited Users*</li>
                                            <li>Unlimited Participantss</li>
                                            <li>Unlimited Custom Forms</li>
                                            <li>Unlimited Form Submissions per Month</li>
                                            <li>Unlimited Custom Case Plans</li>
                                            <li className="success-message">File Uploads</li>
                                            <li>1GB Form Data per User</li>
                                            <li className="success-message">3GB File Data per User</li>
                                            <li>Unlimited Basic Reports</li>
                                            <li className="success-message">Unlimited Advanced Reports</li>
                                            <li>Email Support</li>
                                            <li>Self-Help Guides</li>
                                            <li className="success-message">8 Hours Free 1-on-1 Onboarding</li>
                                        </ul>
                                    </div>
                                    <div className="row justify-content-end">
                                        <div className="column-auto">
                                            <Button id="start-free-trial"
                                                text="Start Free Trial"
                                                onClick={() => setPlan(TRIAL_PLAN_TYPE_ID)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="column-large-10">

                            <div className="text-sub margin-top-3">
                                *No limit on the number of users that can be created.  Costs $15 per month per user created.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="column-large-8">
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <Button id="confirm-plan-button"
                                text="Confirm Plan Selection"
                                onClick={handleOnConfirm} 
                                loading={submitting}
                                disabled={!plan || submitting}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default SelectPlan;
