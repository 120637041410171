import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Input from '../components/UI/Input';
import Loader from '../components/UI/Loader';
import useElementVisible from '../hooks/useElementVisible';
import User from '../models/serviceModels/User';
import { activateUser, deactivateUser, searchUsers } from '../services/userService';
import { MeContext, OrganizationContext } from '../App';
import { FREE_PLAN_TYPE_ID } from '../constants/planTypes';
import WarningMessages from '../components/UI/WarnginMessages';

const TAKE = 10;

const ManageUsers = () => {
    const [paging, setPaging] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [users, setUsers] = useState<User[]>();
    const [totalCount, setTotalCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [query, setQuery] = useState('');
    const [error, setError] = useState<string>('');
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const loaded = useRef(false);
    const [toggleActivation, setToggleActivation] = useState(false);
    const me = useContext(MeContext);
    const organizationContext = useContext(OrganizationContext);

    useEffect(() => {
        document.title = "Manage Users"
    }, []);

    useEffect(() => {
        organizationContext.refresh();
    }, []);

    useEffect(() => {
        if (!loaded.current) {
            return;
        }
        setPaging(true);
        setError('');
        searchUsers(query, skip, TAKE).then(userResults => {
            setUsers(u => {
                if (!u) {
                    return [
                        ...userResults.data
                    ];
                }

                return [
                    ...u,
                    ...userResults.data
                ];
            });

            setTotalCount(userResults.totalCount);
        }).catch(e => {
            console.error(e);
            setError('Failed to load results');
        }).finally(() => {
            setPaging(false);
        });
    }, [skip]);

    useEffect(() => {
        setLoading(true);
        searchUsers(query, skip, TAKE).then(userResults => {
            setUsers(userResults.data);
            setTotalCount(userResults.totalCount);
        }).catch(e => {
            console.error(e);
            setError('Failed to load');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (visible) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible]);

    useEffect(() => {
        loaded.current = true;
    }, []);

    const handleOnSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError('');
        setSearching(true);
        searchUsers(query, skip, TAKE).then(userResults => {
            setUsers(userResults.data);
            setTotalCount(userResults.totalCount);
        }).catch(e => {
            console.error(e);
            setError('Failed to search');
        }).finally(() => {
            setSearching(false);
        });
    };

    const lockDownUsers = organizationContext.value?.planTypeId === FREE_PLAN_TYPE_ID &&
        organizationContext.value?.activeUserCount >= 2;

    const handleOnActivate = (userId: string) => {
        setError('');
        if (lockDownUsers) {
            return;
        }
        setToggleActivation(true);

        activateUser(userId).then(userResult => {
            setUsers(cu => {
                if (!cu) return undefined;
                const existingUser = cu.find(u => u.id === userId);
                if (!existingUser) return cu;

                existingUser.deactivated = userResult.deactivated;
                existingUser.deactivatedBy = userResult.deactivatedBy;

                return [
                    ...cu
                ];
            });
            organizationContext.refresh();
        }).catch(e => {
            console.error(e);
            setError('Failed to activate user');
        }).finally(() => {
            setToggleActivation(false);
        });
    };

    const handleOnDeactivate = (userId: string) => {
        setError('');
        setToggleActivation(true);

        deactivateUser(userId).then(userResult => {
            setUsers(cu => {
                if (!cu) return undefined;
                const existingUser = cu.find(u => u.id === userId);
                if (!existingUser) return cu;

                existingUser.deactivated = userResult.deactivated;
                existingUser.deactivatedBy = userResult.deactivatedBy;

                return [
                    ...cu
                ];
            });
            organizationContext.refresh();
        }).catch(e => {
            console.error(e);
            setError('Failed to deactivate user');
        }).finally(() => {
            setToggleActivation(false);
        });
    };

    const getResults = () => {
        return (<div className="row margin-vertical-2 justify-content-center">
            <div className="column-medium">
                {organizationContext.value?.planTypeId === FREE_PLAN_TYPE_ID && <WarningMessages messages={['You can have up to 2 active users in the Free Plan. Upgrade to a paid plan if you need to create more users for your organization.']} />}
                <table className="table">
                    <thead>
                        <td>Name</td>
                        <td>Role</td>
                        <td>Email</td>
                        <td>Phone Number</td>
                        <td>Status</td>
                        <td></td>
                        <td></td>
                    </thead>
                    <tbody>
                        {users && me.user && users.map(u => {
                            return (<tr key={u.id} className="table-row">
                                <td>{u.firstName} {u.lastName}</td>
                                <td>{u.userRoleName}</td>
                                <td>{u.email}</td>
                                <td>{u.phoneNumber}</td>
                                <td>{(u.deactivated ? 'Inactive' : 'Active')}</td>
                                <td>
                                    <Button text="Edit"
                                        className="small"
                                        id={`edit-button-${u.id}`}
                                        href={`/users/${u.id}/edit`} />
                                </td>
                                <td>
                                    {me.user?.id !== u.id && (<div className="row">
                                        {(u.deactivated && <div className="column-auto">
                                            <Button text="Activate"
                                                disabled={toggleActivation || lockDownUsers}
                                                className="small secondary"
                                                id={`activate-button-${u.id}`}
                                                onClick={() => handleOnActivate(u.id)} />
                                        </div>)}
                                        {(!u.deactivated && <div className="column-auto">
                                            <Button text="Deactivate"
                                                disabled={toggleActivation}
                                                className="small secondary"
                                                id={`deactivate-button-${u.id}`}
                                                onClick={() => handleOnDeactivate(u.id)} />
                                        </div>)}
                                    </div>)}
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div >
        </div >);
    };

    const getMainContent = () => {
        return (
            <section id="main-content" tabIndex={-1}>
                {loading && <Loader />}
                {!loading && (
                    <>
                        <h1>Manage Users</h1>
                        <div className="row justify-content-center margin-vertical-2">
                            <div className="column-medium">
                                <ErrorMessages messages={[error]} />
                                <div className="row">
                                    <div className="column-medium">
                                        <form onSubmit={handleOnSubmit}>
                                            <div className="row">
                                                <div className="column">
                                                    <label htmlFor='filter-users-input'>User's Name or Role</label>
                                                    <Input id="filter-users-input"
                                                        placeholder="Filter by a user's name or role"
                                                        value={query}
                                                        onChange={(e) => setQuery(e)} />
                                                </div>
                                                <div className="column-auto align-self-end">
                                                    <Button id="filter-users-button"
                                                        className="secondary"
                                                        type="submit"
                                                        text="Filter"
                                                        disabled={searching}
                                                        loading={searching} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                    <div className="column-medium align-self-end">
                                        <div className="row justify-content-end align-items-center">
                                            <div className="column-medium-auto">
                                                {(toggleActivation &&
                                                    <Loader />
                                                )}
                                            </div>
                                            <div className="column-medium-auto">
                                                <div className="row align-items-center">
                                                    <div className="column-auto">
                                                        <Button id="create-user-button"
                                                            text="Create User"
                                                            href="/admin/users/create"
                                                            disabled={lockDownUsers} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {me.user && users && users.length > 0 && getResults()}
                        {me.user && users && users.length === 0 && <div className="row justify-content-center margin-vertical-2">
                            <div className="column-auto">
                                <p>No Users Found</p>
                            </div>
                        </div>}
                        {paging && <Loader />}
                        {me.user && users && users.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
                    </>
                )}
            </section>
        );
    };

    return getMainContent();
};

export default ManageUsers;