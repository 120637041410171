import Loader from "../components/UI/Loader";
import ErrorMessages from "../components/UI/ErrorMessages";
import getclassNames from "../utils/getClassNames";
import useQuotas from "../hooks/useQuotas";
import { useContext, useEffect } from "react";
import { OrganizationContext } from "../App";
import { format } from "date-fns";
import { FREE_PLAN_TYPE_ID } from "../constants/planTypes";

const ManageUsage = () => {
    const { loading, failedToLoad, metrics, limits, refresh } = useQuotas();
    const organizationContext = useContext(OrganizationContext);

    useEffect(() => {
        document.title = 'Organization Usage';
    }, []);

    useEffect(() => {
        refresh();
    }, []);

    return (<section id="main-content" tabIndex={-1}>
        {(loading || organizationContext.loading) && <Loader />}
        {!loading && !organizationContext.loading && (
            <>
                <h1>Organization Usage</h1>
                {organizationContext.value?.planEndDate && <div className="row justify-content-center">
                    <div className="column-auto">
                        Plan End Date: {format(new Date(organizationContext.value!.planEndDate), 'MM/dd/yyyy')}
                    </div>
                </div>}
                <div className="row justify-content-center margin-vertical-2">
                    <div className="column-medium">
                        {failedToLoad && <ErrorMessages messages={['Failed to load usage']} />}
                        {metrics && limits && (<>
                            <div className="row">
                                <div className="column-medium-6 border rounded padding-3">
                                    <div className="row">
                                        <div className="column">
                                            <h2>Overall Usage</h2>
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Form Data
                                        </div>
                                        {limits.maxFormData !== undefined && <div className="column">
                                            {metrics.totalFormDataUsage.toFixed(2)} MB / {(limits.maxFormData === -1 ? 'Unlimited' : limits.maxFormData)} MB
                                        </div>}
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total File Data
                                        </div>
                                        <div className="column">{limits.maxFileData === null ? <>N/A</> :
                                            <>{metrics.totalFileDataUsage.toFixed(2)} MB / {(limits.maxFileData === -1 ? 'Unlimited' : limits.maxFileData)} MB</>}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Active Participants
                                        </div>
                                        <div className="column">
                                            {metrics.totalActiveParticipants} / {(limits.maxParticipants === -1 ? 'Unlimited' : limits.maxParticipants)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Case Plans
                                        </div>
                                        <div className="column">
                                            {metrics.totalCasePlans} / {(limits.maxCasePlans === -1 ? 'Unlimited' : limits.maxCasePlans)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Forms Created
                                        </div>
                                        <div className="column">
                                            {metrics.totalFormCounts} / {(limits.maxForms === -1 ? 'Unlimited' : limits.maxForms)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Form Submissions this Month
                                        </div>
                                        <div className="column">
                                            {metrics.formSubmissionsThisMonth} / {(limits.maxFormSubmissionsPerMonth === -1 ? 'Unlimited' : limits.maxFormSubmissionsPerMonth)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Submitted Basic Reports Today
                                        </div>
                                        <div className="column">
                                            {metrics.submittedBasicReportsToday} / {(limits.basicReportsPerDay === -1 ? 'Unlimited' : limits.basicReportsPerDay)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Submitted Advanced Reports Today
                                        </div>
                                        <div className="column">{limits.advancedReportsPerDay === null ? <>N/A</> :
                                            <>{metrics.submittedAdvancedReportsToday} / {(limits.advancedReportsPerDay === -1 ? 'Unlimited' : limits.advancedReportsPerDay)}</>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column-medium-6">
                                    <h2>Submitted Basic Reports Today</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">user</th>
                                                <th scope="col">submissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {metrics.userSubmittedBasicReports && metrics.userSubmittedBasicReports.map((sr, i: number) => {
                                                return (
                                                    <tr key={i} className={getclassNames('table-row', i % 2 === 0 ? 'even-row' : '')}>
                                                        <td>{sr.name}</td>
                                                        <td>{sr.total}</td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="column-medium-6">
                                    <h2>Submitted Advanced Reports Today</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">user</th>
                                                <th scope="col">submissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {metrics.userSubmittedAdvancedReports && metrics.userSubmittedAdvancedReports.map((sr, i: number) => {
                                                return (
                                                    <tr key={i} className={getclassNames('table-row', i % 2 === 0 ? 'even-row' : '')}>
                                                        <td>{sr.name}</td>
                                                        <td>{sr.total}</td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="column-medium-6">
                                    <h2>Data Usage</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">user</th>
                                                <th scope="col">total form data</th>
                                                <th scope="col">total file data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {metrics.userDataUsage && metrics.userDataUsage.map((sr, i: number) => {
                                                return (<tr key={i} className={getclassNames('table-row', i % 2 === 0 ? 'even-row' : '')}>
                                                    <td>{sr.name}</td>
                                                    <td>{sr.totalFormData.toFixed(2)} MB</td>
                                                    <td>{sr.totalFileData.toFixed(2)} MB</td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            </>)
        }
    </section>);
};

export default ManageUsage;