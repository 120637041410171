import { DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES, SUBMITTED_FORMS } from "./reportTypes";

const ORGANIZATION = 'ORGANIZATION';
const PROGRAMS = 'PROGRAMS';
const FORMS = 'FORMS';
const CUSTOM_FILTER = 'CUSTOM_FILTER';
const ACTIVITY_DATE_RANGE = 'ACTIVITY_DATE_RANGE'; 

const DEMOGRAPHICS_PERSPECTIVES = [
    ORGANIZATION,
    PROGRAMS,
    CUSTOM_FILTER
];

const SUBMITTED_FORMS_PERSPECTIVES = [
    ORGANIZATION,
    PROGRAMS
];

const SUBMITTED_FORMS_PERSPECTIVES_WITH_CUSTOM_FILTER = [
    ACTIVITY_DATE_RANGE,
    CUSTOM_FILTER
];

const PERSPECTIVE_NAME_MAP: {[key: string]: string} = {
    [FORMS]: 'Submitted Forms',
    [ORGANIZATION]: 'Entire Organization',
    [PROGRAMS]: 'Program Enrollment',
    [CUSTOM_FILTER]: 'Custom Filter',
    [ACTIVITY_DATE_RANGE]: 'Activity Date'
};

const reportTypePerspectivesMap: { [key: string]: string[] } = {
    [DEMOGRAPHICS]: DEMOGRAPHICS_PERSPECTIVES,
    [OUTPUTS_AND_OUTCOMES]: SUBMITTED_FORMS_PERSPECTIVES,
    [SUBMITTED_FORMS]: SUBMITTED_FORMS_PERSPECTIVES_WITH_CUSTOM_FILTER
};

export {
    ORGANIZATION,
    PROGRAMS,
    FORMS,
    CUSTOM_FILTER,
    DEMOGRAPHICS_PERSPECTIVES,
    SUBMITTED_FORMS_PERSPECTIVES,
    PERSPECTIVE_NAME_MAP,
    ACTIVITY_DATE_RANGE,
    reportTypePerspectivesMap
};