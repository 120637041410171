import { useMemo } from 'react';
import { FREE_PLAN_TYPE_ID, STANDARD_PLAN_TYPE_ID, TRIAL_PLAN_TYPE_ID } from '../../constants/planTypes';
import { getItem } from '../../services/cacheService';
import { formatCurrency } from '../../utils/stringHelpers';

const NewPlan = () => {
    const newPlan = useMemo(() => {
        return getItem<{ users: number, plan: number }>('new_plan')
    }, []);

    const newPlanCost = useMemo(() => {
        if (!newPlan) return;

        if (newPlan.plan === STANDARD_PLAN_TYPE_ID) {
            return 15;
        } else if (newPlan.plan === FREE_PLAN_TYPE_ID) {
            return 0;
        } else {
            return 0;
        }
    }, [newPlan]);

    const newPlanName = useMemo(() => {
        if (!newPlan) return;

        if (newPlan.plan === STANDARD_PLAN_TYPE_ID) {
            return 'Standard';
        } else if (newPlan.plan === FREE_PLAN_TYPE_ID) {
            return 'Free';
        } else if (newPlan.plan === TRIAL_PLAN_TYPE_ID) {
            return 'Trial';
        } else {
            return undefined;
        }
    }, [newPlan]);

    const getNewPlan = () => {
        return (<>
            <div className="row">
                <div className="column">
                    <h3>New Plan</h3>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <div className="card">
                        <div className="card-container small">
                            <div>
                                {newPlan && newPlanCost !== undefined && (
                                    <>
                                        <h4><strong>{newPlanName}</strong></h4>
                                        {formatCurrency(newPlanCost)} x {newPlan.users} users
                                        <hr />
                                        Total per Month: {formatCurrency(newPlanCost * newPlan.users)}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    };
    
    if (!newPlan) {
        return null;
    }

    return (
        <div className="row">
            <div className="column">
                {getNewPlan()}
            </div>
        </div>
    );
};

export default NewPlan;