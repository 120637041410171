import axios from "axios";
import GetCheckoutUrlRequest from "../models/serviceModels/GetCheckoutUrlRequest";
import GetCheckoutUrlResponse from "../models/serviceModels/GetCheckoutUrlResponse";
import { UpdatePlanRequest } from "../models/serviceModels/UpdatePlanRequest";
import { OrganizationPlan } from "../models/serviceModels/OrganizationPlan";
import { GetPaymentMethodUrlResponse } from "../models/serviceModels/GetPaymentMethodUrlResponse";
import { CreateSubscriptionRequest } from "../models/serviceModels/CreateSubscriptionRequest";
import { PaymentMethod } from "../models/serviceModels/PaymentMethod";
import { UpdateSubscriptionRequest } from "../models/serviceModels/UpdateSubscriptionRequest";
import { UpdatePaymentMethodRequest } from "../models/serviceModels/UpdatePaymentMethodRequest";
import { ReplacePaymentMethodRequest } from "../models/serviceModels/ReplacePaymentMethodRequest";
import { CreatePaymentMethodRequest } from "../models/serviceModels/CreatePaymentMethodRequest";
import { CheckoutRequest } from "../models/serviceModels/CheckoutRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getCheckoutUrl = async (request: GetCheckoutUrlRequest): Promise<GetCheckoutUrlResponse>  => {
    const response = await axios.post(`${baseUrl}/sessions/checkouts`, request);
    return response.data;
};

const getPaymenMethodsUrl = async (): Promise<GetPaymentMethodUrlResponse> => {
    const response = await axios.post(`${baseUrl}/sessions/payment-methods`);
    return response.data;
}

const updatePlan = async (request: UpdatePlanRequest): Promise<OrganizationPlan> => {
    const response = await axios.post(`${baseUrl}/plans`, request);
    return response.data;
};

const getPlan = async (): Promise<OrganizationPlan> => {
    const response = await axios.get(`${baseUrl}/plans`);
    return {
       ...response.data,
       maxForms: Number(response.data.maxForms),
       maxRecords: Number(response.data.maxRecords),
       maxData: Number(response.data.maxData),
       maxReportRunsPerDay: Number(response.data.maxReportRunsPerDay),
       maxDataPerUser: Number(response.data.maxDataPerUser)
    };
};

const getPaymentMethod = async (): Promise<PaymentMethod> => {
    const response = await axios.get(`${baseUrl}/payments/methods`);
    return response.data;
}

const createSubscription = async (request: CreateSubscriptionRequest): Promise<OrganizationPlan> => {
    const response = await axios.post(`${baseUrl}/payments/subscriptions`, request);
    return response.data;
};

const activateSubscription = async (): Promise<OrganizationPlan> => {
    const response = await axios.post(`${baseUrl}/payments/subscriptions/activate`);
    return response.data;
};

const deactivateSubscription = async (): Promise<OrganizationPlan> => {
    const response = await axios.post(`${baseUrl}/payments/subscriptions/deactivate`);
    return response.data;
};

const checkout = async (request: CheckoutRequest): Promise<void> => {
    await axios.post(`${baseUrl}/payments/checkout`, request);
}

const updateSubscription = async (request: UpdateSubscriptionRequest): Promise<OrganizationPlan> => {
    const response = await axios.put(`${baseUrl}/payments/subscriptions`, request);
    return response.data;
};

const updatePaymentMethod = async (request: UpdatePaymentMethodRequest): Promise<PaymentMethod> => {
    const response = await axios.put(`${baseUrl}/payments/methods`, request);
    return response.data;
};

const replacePaymentMethod = async (request: ReplacePaymentMethodRequest): Promise<PaymentMethod> => {
    const response = await axios.post(`${baseUrl}/payments/methods/replace`, request);
    return response.data;
};

const createPaymentMethod = async (request: CreatePaymentMethodRequest): Promise<PaymentMethod> => {
    const response = await axios.post(`${baseUrl}/payments/methods`, request);
    return response.data;
};

export {
    updatePaymentMethod,
    replacePaymentMethod,
    createPaymentMethod,
    getCheckoutUrl,
    getPaymenMethodsUrl,
    updatePlan,
    getPlan,
    createSubscription,
    getPaymentMethod,
    updateSubscription,
    activateSubscription,
    deactivateSubscription,
    checkout
}