import axios from "axios";
import { FormEvent, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import ValidatedInput from "../components/UI/ValidatedInput";
import ModelErrors from "../models/pageModels/ModelErrors";
import RegisterPageModel from "../models/pageModels/RegisterPageModel";
import { getDefaultErrors } from "../utils/modelHelpers";
import ValidatedSelect from "../components/UI/ValidatedSelect";
import states from "../constants/states";
import { useMe } from "../hooks/useMe";
import { MeContext, OrganizationContext } from "../App";

const getDefaultModel = (): RegisterPageModel => {
    return {
        organizationName: '',
        organizationUrl: '',
        organizationEmail: '',
        organizationPhoneNumber: '',
        organizationAddressLine1: '',
        organizationAddressLine2: '',
        organizationCity: '',
        organizationState: '',
        organizationZipCode: '',
        userFirstName: '',
        userLastName: '',
        userPhoneNumber: ''
    };
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const RegisterPage = () => {
    const me = useContext(MeContext);
    const organization = useContext(OrganizationContext);
    const [model, setModel] = useState<RegisterPageModel>(getDefaultModel());
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors(getDefaultModel()));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Register Organization"
    }, []);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors(getDefaultModel());
        let isValid = true;

        if (!model.organizationName) {
            isValid = false;
            newErrors.organizationName.push('Name is required');
        }

        if (!model.organizationEmail) {
            isValid = false;
            newErrors.organizationEmail.push('Email is required');
        } else if (model.organizationEmail.indexOf('@') === -1 || model.organizationEmail.indexOf('.') === -1) {
            isValid = false;
            newErrors.organizationEmail.push('Email is not a valid format');
        }

        if (!model.organizationPhoneNumber) {
            isValid = false;
            newErrors.organizationPhoneNumber.push('Phone Number is required');
        }

        if (!model.organizationAddressLine1) {
            isValid = false;
            newErrors.organizationAddressLine1.push('Address Line 1 is required');
        }

        if (!model.organizationCity) {
            isValid = false;
            newErrors.organizationCity.push('City is required');
        }

        if (!model.organizationState) {
            isValid = false;
            newErrors.organizationState.push('State is required');
        }

        if (!model.organizationZipCode) {
            isValid = false;
            newErrors.organizationZipCode.push('Zip is required');
        }

        if (!model.userFirstName) {
            isValid = false;
            newErrors.userFirstName.push('First Name is required');
        }

        if (!model.userLastName) {
            isValid = false;
            newErrors.userLastName.push('Last Name is required');
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitErrors(null);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        axios.post(`${baseUrl}/users/register`, {
            ...model
        }).then(() => {
            me.refresh();
            organization.refresh();
            navigate('/plan/select');
        }).catch(e => {
            setSubmitErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <div className="section">
            <div className="row justify-content-center">
                <div className="column-medium-6">
                    <h1 className="form-header">Register</h1>
                    <form onSubmit={handleOnSubmit}>
                        <div className="row">
                            <div className="column">
                                <h1 className="section-header">Organzation Details</h1>
                            </div>
                        </div>
                        <ApiErrorMessages error={submitErrors} />
                        <div className="row margin-vertical-2">
                            <div className="column">
                                <ValidatedInput id="organization-name" required={true}
                                    value={model.organizationName}
                                    onChange={(e) => handleOnChange('organizationName', e)}
                                    label="Organization Name"
                                    messages={(submitted && modelErrors.organizationName) || []} />
                            </div>
                        </div>
                        <div className="row margin-vertical-2">
                            <div className="column-medium-4">
                                <Input id="organization-url"
                                    value={model.organizationUrl}
                                    onChange={(e) => handleOnChange('organizationUrl', e)}
                                    label="URL" />
                            </div>
                            <div className="column-medium-4">
                                <ValidatedInput id="organization-email" required={true}
                                    value={model.organizationEmail}
                                    onChange={(e) => handleOnChange('organizationEmail', e)}
                                    label="E-Mail"
                                    messages={(submitted && modelErrors.organizationEmail) || []} />
                            </div>
                            <div className="column-medium-4">
                                <ValidatedInput id="organization-phone-number" required={true}
                                    value={model.organizationPhoneNumber}
                                    onChange={(e) => handleOnChange('organizationPhoneNumber', e)}
                                    label="Phone Number"
                                    mask="(000)000-0000"
                                    useMaskedValue={true}
                                    messages={(submitted && modelErrors.organizationPhoneNumber) || []} />
                            </div>
                        </div>
                        <div className="row margin-vertical-2">
                            <div className="column">
                                <ValidatedInput id="organization-address-line-1" required={true}
                                    value={model.organizationAddressLine1}
                                    onChange={(e) => handleOnChange('organizationAddressLine1', e)}
                                    label="Address Line 1"
                                    messages={(submitted && modelErrors.organizationAddressLine1) || []} />
                            </div>
                        </div>
                        <div className="row margin-vertical-2">
                            <div className="column">
                                <Input id="organization-address-line-2"
                                    value={model.organizationAddressLine2}
                                    onChange={(e) => handleOnChange('organizationAddressLine2', e)}
                                    label="Address Line 2" />
                            </div>
                        </div>
                        <div className="row margin-vertical-2">
                            <div className="column-medium-4">
                                <ValidatedInput id="organization-city" required={true}
                                    value={model.organizationCity}
                                    onChange={(e) => handleOnChange('organizationCity', e)}
                                    label="City"
                                    messages={(submitted && modelErrors.organizationCity) || []} />
                            </div>
                            <div className="column-medium-4">
                                <ValidatedSelect id="organization-state" required={true}
                                    value={model.organizationState}
                                    onChange={(e) => handleOnChange('organizationState', e)}
                                    label="State"
                                    messages={(submitted && modelErrors.organizationState) || []}>
                                    <option value="">Select One</option>
                                    {states.map((s, i) => {
                                        return <option key={i} value={s.value}>{s.label}</option>
                                    })}
                                </ValidatedSelect>
                            </div>
                            <div className="column-medium-4">
                                <ValidatedInput id="organization-zip" required={true}
                                    value={model.organizationZipCode}
                                    onChange={(e) => handleOnChange('organizationZipCode', e)}
                                    label="Zip"
                                    messages={(submitted && modelErrors.organizationZipCode) || []} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <h2 className="section-header">Your Details</h2>
                            </div>
                        </div>
                        <div className="row margin-vertical-2">
                            <div className="column-medium-3">
                                <ValidatedInput id="user-first-name" required={true}
                                    value={model.userFirstName}
                                    onChange={(e) => handleOnChange('userFirstName', e)}
                                    label="First Name"
                                    messages={(submitted && modelErrors.userFirstName) || []} />
                            </div>
                            <div className="column-medium-3">
                                <ValidatedInput id="user-last-state" required={true}
                                    value={model.userLastName}
                                    onChange={(e) => handleOnChange('userLastName', e)}
                                    label="Last Name"
                                    messages={(submitted && modelErrors.userLastName) || []} />
                            </div>
                            <div className="column-medium-3">
                                <Input id="user-phone-number"
                                    value={model.userPhoneNumber}
                                    onChange={(e) => handleOnChange('userPhoneNumber', e)}
                                    mask="(000)000-0000"
                                    useMaskedValue={true}
                                    label="Phone Number" />
                            </div>
                        </div>
                        <div className="row justify-content-end margin-top-4">
                            <div className="column-auto">
                                <Button id="submit-button" 
                                    type="submit" 
                                    text="Submit" 
                                    loading={submitting} 
                                    disabled={submitting} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;