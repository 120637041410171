import React, { useEffect, useState } from 'react';
import Button from '../../../UI/Button';
import DisplayFieldProps from './Models/DisplayFieldProps';
import Dropdown from '../../../UI/Dropdown';

const HeaderDisplayField = (props: DisplayFieldProps) => {
    const [showAdvanced, setShowAdvanced] = useState(props.showAdvanced);

    useEffect(() => {
        setShowAdvanced(props.showAdvanced);
    }, [props.showAdvanced]);

    const options = [
        {
            label: 'Edit',
            onClick: () => props.onEdit(props.field)
        },
        {
            label: 'Delete',
            onClick: () => props.onDelete(props.field)
        }
    ];

    return (<><div className="field-wrapper">
        <div className="row justify-content-between align-items-center">
            <div className="column">
                <h3>{props.field.label || 'Unlabeled Header'}</h3>
            </div>
            {!props.hideButtons && <div className="column-auto margin-top-5">
                <Dropdown id={`dropdown-${props.field.id}`} options={options} />
            </div>}
        </div>
        <div className="margin-vertical-3">
            <i className="ri-heading margin-start-1"></i> Heading
        </div>
        <div className="margin-vertical-3">
            <Button id="toggle-advanced-button"
                className="flat small"
                text={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
                icon={showAdvanced ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}
                iconLocation="end" onClick={() => setShowAdvanced(!showAdvanced)} />

        </div>
        {showAdvanced && <div className="advanced-content">
            <label>Name:</label>{props.field.name}
        </div>}
    </div>
    </>);
};

export default HeaderDisplayField;