import React from 'react';
import './WarningMessages.css';

export interface WarningMessagesProps {
    messages: string[];
}

const WarningMessages = (props: WarningMessagesProps) => {
    if (!props.messages || props.messages.length === 0) {
        return null;
    }

    return (<div className="warning-container">
        {props.messages.map((m, i) => {
            return <div className="warning-message" key={i}>{m}</div>
        })}
        </div>);
};

export default WarningMessages;