import { useContext, useMemo } from "react";
import getclassNames from "../../../utils/getClassNames";
import { CUSTOM_FILTER, PERSPECTIVE_NAME_MAP } from "../../../constants/reportPerspective";
import IconButton from "../../UI/IconButton";
import RadioButton from "../../UI/RadioButton";
import { getReportPerspectiveOptions } from "../../../utils/radiobuttonHelpers";
import Button from "../../UI/Button";
import { REPORT_PERSPECTIVE } from "../../../constants/reportStep";
import ReportStepProps from "./ReportStepProps";
import { OrganizationContext } from "../../../App";
import { FREE_PLAN_TYPE_ID } from "../../../constants/planTypes";
import WarningMessages from "../../UI/WarnginMessages";

const ReportPerspective = (props: ReportStepProps) => {
    const organizationContext = useContext(OrganizationContext);

    const value = props.values.length === 1 ? props.values[0].value : '';

    const handleOnNext = () => {
        props.onNext(REPORT_PERSPECTIVE);
    };

    const handleOnChange = (value: string) => {
        props.onChange({ step: REPORT_PERSPECTIVE, values: [{ name: 'perspective', value }] });
    };

    const handleToggleExpand = () => {
        props.onExpand(REPORT_PERSPECTIVE, !props.expanded);
    };

    const lockDownAdvancedReports = organizationContext.value?.planTypeId === FREE_PLAN_TYPE_ID;

    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
        <div className="report-step-header">
            <div className="row justify-content-between align-items-center">
                <div className="column-auto">
                    <h3>That lines up with?{value ? ` - ${PERSPECTIVE_NAME_MAP[value]}` : ''}</h3>
                </div>
                <div className="column-1">
                    <IconButton id="toggle-report-perspective-expansion"
                        className="small flat"
                        disabled={props.disabled}
                        icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                        onClick={handleToggleExpand} />
                </div>
            </div>
        </div>
        <div className="report-step-body">
            {lockDownAdvancedReports && <WarningMessages messages={["Custom Filter is disabled for the Free Plan. Upgrade to a paid plan to unlock the Custom Filter option."]} />}
            <div className="row margin-bottom-3">
                <div className="column">
                    <RadioButton id="report-perspective"
                        options={getReportPerspectiveOptions(props.reportType).map(o => {
                            return lockDownAdvancedReports && o.value === CUSTOM_FILTER ? {
                                ...o,
                                disabled: true
                            } : o
                        })}
                        onChange={handleOnChange}
                        disabled={props.disabled}
                        value={value} />
                </div>
            </div>
            <div className="row justify-content-end margin-bottom-3">
                <div className="column-auto">
                    <Button id="report-type-next"
                        text="Next"
                        icon={<i className="ri-arrow-right-s-line"></i>}
                        iconLocation="end"
                        className="secondary"
                        disabled={!value || props.disabled}
                        onClick={handleOnNext} />
                </div>
            </div>
        </div>
    </div>);
};

export default ReportPerspective;