import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import './FileUpload.css';
import getclassNames from "../../utils/getClassNames";
import { getDownloadUrl, getFileUpload, getUploadUrl, uploadFile } from "../../services/fileService";
import Loader from "./Loader";
import Button from "./Button";
import axios, { CancelTokenSource } from "axios";
import ApiErrorMessages from "./ApiErrorMessages";
import UploadableFile from "../../models/pageModels/UploadableFile";
import Modal from "./Modal";
import * as status from "../../constants/fileUploadStatus";
import * as scanStatus from "../../constants/fileScanStatus";
import { format } from "date-fns";
import Dropdown from "./Dropdown";
import { OrganizationContext } from "../../App";
import { FREE_PLAN_TYPE_ID } from "../../constants/planTypes";
import WarningMessages from "./WarnginMessages";

interface FileUploadProps {
    label?: string;
    id: string;
    multiple?: boolean;
    onChange?: (value: UploadableFile[]) => void;
    value?: UploadableFile[]
    disabled?: boolean;
    disableRemove?: boolean;
    disableUpload?: boolean;
}

const FileUpload = (props: FileUploadProps) => {
    const [files, setFiles] = useState<UploadableFile[]>(props.value || []);
    const [uploadCancelTokens, setUploadCancelTokens] = useState<{ file: UploadableFile, token: CancelTokenSource }[]>([]);
    const [apiError, setApiError] = useState<any>();
    const [openingFiles, setOpeningFiles] = useState<string[]>([]);
    const [urlCache, setUrlCache] = useState<{ fileId: string, objectUrl: string }[]>([]);
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [fileToRemove, setFileToRemove] = useState<UploadableFile>();
    const organizationContext = useContext(OrganizationContext);

    useEffect(() => {
        if (files && files.length != 0 && (!props.value || props.value.length === 0)) {
            setFiles([]);
        }
    }, [props.value]);

    useEffect(() => {
        if (!files.some(f => f.status === status.NEW)) {
            return;
        }

        const newFiles = files.filter(f => f.status === status.NEW);
        newFiles.forEach(nf => nf.status = status.STARTED);

        setFiles([
            ...files
        ]);
        Promise.all(newFiles.map(nf => getUploadUrl({
            fileName: nf.name,
            size: nf.file!.size
        }))).then(responses => {
            let changeMade = false;
            for (const response of responses) {
                const matchingFile = files.find(f => f.name === response.fileName);
                if (!matchingFile) {
                    console.warn('No file foud matching ', response.fileName);
                    continue;
                }

                matchingFile.url = response.url;
                matchingFile.fileId = response.id;
                changeMade = true;
            }

            if (changeMade) {            

                setFiles([
                    ...files
                ]);
            }
        }).catch(e => {
            console.error(e);
            files.forEach(nf => nf.status = status.FAILED);            

            setFiles([
                ...files
            ]);
        })
    }, [files]);

    useEffect(() => {
        const timeoutReferences: any[] = [];
        timeoutReferences.push(pollFileUploadStatus(5000, timeoutReferences));

        return () => {
            for (const reference of timeoutReferences) {
                if (reference) {
                    clearTimeout(reference);

                }
            }
        }
    }, [files]);

    const pollFileUploadStatus = (interval: number, timeoutReferences: any[]) => {
        return setTimeout(() => {
            const filteredFiles = files.filter(f => f.status === status.SCANNING && f.fileId);
            if (filteredFiles.length === 0) {
                timeoutReferences.push(pollFileUploadStatus(interval * 2, timeoutReferences));
            } else {
                const requests = filteredFiles.map(f => getFileUpload(f.fileId!));
                Promise.all(requests).then(uploads => {
                    const matchingOriginalFiles = files.filter(f => filteredFiles.some(ff => ff.fileId == f.fileId));
                    let madeChange = false;
                    for (const upload of uploads) {
                        const matchingFile = matchingOriginalFiles.find(f => f.fileId === upload.id);
                        if (matchingFile) {
                            const originalStatus = matchingFile.status;
                            matchingFile.status = upload.status === scanStatus.NEW ? status.SCANNING :
                                upload.status === scanStatus.INFECTED ? status.INFECTED :
                                    status.UPLOADED;
                            madeChange = originalStatus !== matchingFile.status;
                        }
                    }

                    if (madeChange) {
                        setFiles([
                            ...files
                        ]);
                    }
                }).catch(e => {
                    console.error(e);
                    const matchingOriginalFiles = files.filter(f => filteredFiles.some(ff => ff.fileId == f.fileId));
                    matchingOriginalFiles.forEach(f => f.status = status.FAILED);

                    setFiles([
                        ...files
                    ]);
                }).finally(() => {
                    timeoutReferences.push(pollFileUploadStatus(interval, timeoutReferences));
                });
            }
        }, interval);

    };

    useEffect(() => {
        if (!props.onChange) {
            return;
        }
        
        props.onChange!(files);
    }, [files]);

    useEffect(() => {
        if (!files.some(f => f.status === status.STARTED && f.url)) {
            return;
        }

        setApiError(undefined);
        const readyFiles = files.filter(f => f.status === status.STARTED && f.url && f.file && f.fileId);
        readyFiles.forEach(rf => rf.status = status.UPLOADING);

        setFiles([
            ...files
        ]);

        Promise.all(readyFiles.map(rf => {
            const sourceToken = axios.CancelToken.source();
            setUploadCancelTokens(ct => [
                ...ct,
                {
                    file: rf,
                    token: sourceToken
                }
            ]);
            return uploadFile({
                file: rf.file!,
                cancellationToken: sourceToken,
                url: rf.url!,
                fileId: rf.fileId!
            });
        })).then((fileIds) => {
            const uploadedFiles = files.filter(f => fileIds.includes(f.fileId!));
            if (uploadedFiles.length === 0) {
                console.warn('Files finished uploading but they are not found to update as as uploaded');
                return;
            }

            uploadedFiles.forEach(uf => {
                uf.status = status.SCANNING;
                uf.url = undefined;
            });

            setFiles([
                ...files
            ]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
            const sentFileIds = readyFiles.map(f => f.fileId);
            const failedFiles = files.filter(f => sentFileIds.includes(f.fileId));
            if (failedFiles.length === 0) {
                console.warn('Files failed to upload but they are not found to update as as Failed');
                return;
            }

            failedFiles.forEach(ff => {
                ff.status = status.FAILED
            });

            setFiles([
                ...files
            ]);
        });
    }, [files]);

    const handleOnDrop = useCallback((files: File[]) => {
        setFiles(ef => {
            if (props.multiple) {
                return [
                    ...files.map<UploadableFile>(f => {
                        return {
                            name: f.name,
                            file: f,
                            status: status.NEW
                        }
                    }),
                    ...ef
                ]
            }

            return files.length > 0 ? [
                {
                    name: files[0].name,
                    file: files[0],
                    status: status.NEW
                }
            ] : []
        });
    }, [props.multiple]);

    const confirmOnRemove = (file: UploadableFile) => {
        setConfirmRemove(true);
        setFileToRemove(file);
    };

    const handleOnRemove = () => {
        if (!fileToRemove) {
            console.warn('No file found, unable to remove');
            return;
        }

        if (fileToRemove.status === status.UPLOADING) {
            const cancelToken = uploadCancelTokens.find(t => t.file === fileToRemove);
            if (cancelToken) {
                cancelToken.token.cancel('User requested cancel');
            }
        }

        setFiles(currentFiles => [
            ...currentFiles.filter(f => f !== fileToRemove)
        ]);

        handleOnCancelRemoval();
    };

    const handleOnRetry = (file: UploadableFile) => {
        if (file.status !== status.FAILED) {
            console.warn('Unable to retry unless status is Failed');
            return;
        }

        // if it has a URL we created the upload record, but failed to upload
        // otherwise we just failed to create the upload record
        file.status = file.url ? status.STARTED : status.NEW;

        setFiles([
            ...files
        ]);
    };

    const handleOnOpen = (file: UploadableFile) => {
        setApiError(undefined);

        if (file.status !== status.UPLOADED) {
            console.warn('Unable to open unless status is Uploaded');
            return;
        }

        if (file.file && file.fileId) {
            const existingUrl = urlCache.find(c => c.fileId === file.fileId);
            const anchor = document.createElement('a');
            anchor.setAttribute('download', 'download');

            if (!existingUrl) {
                const objectUrl = URL.createObjectURL(file.file);
                anchor.href = objectUrl;

                setTimeout(() => {
                    if (objectUrl) {
                        URL.revokeObjectURL(objectUrl);
                    }
                }, 600000);

                setUrlCache(c => [
                    ...c,
                    { fileId: file.fileId!, objectUrl }
                ]);
            } else {
                anchor.href = existingUrl.objectUrl
            }

            anchor.click();
        } else if (file.fileId) {
            setOpeningFiles(f => [
                ...f,
                file.fileId!
            ]);

            getDownloadUrl({
                fileId: file.fileId
            }).then(r => {
                if (r.url) {
                    const anchor = document.createElement('a');
                    anchor.href = r.url;
                    anchor.setAttribute('download', 'download');
                    anchor.click();
                }
            }).catch(e => {
                console.error(e);
                setApiError(e);
            }).finally(() => {
                setOpeningFiles(f => [
                    ...f.filter(o => o !== file.fileId)
                ]);
            })
        } else {
            console.error('Unable to download file.  No File or FileId reference found');
        }
    };

    const handleOnCancelRemoval = () => {
        setFileToRemove(undefined);
        setConfirmRemove(false);
    };

    const lockDownFiles = organizationContext.value?.planTypeId === FREE_PLAN_TYPE_ID;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleOnDrop,
        multiple: props.multiple,
        disabled: props.disabled || props.disableUpload || lockDownFiles,
        maxSize: 1024 * 1024 * 50,
        accept: {
            'text/csv': ['.csv'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'image/gif': ['.gif'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'application/vnd.oasis.opendocument.presentation': ['.odp'],
            'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
            'application/vnd.oasis.opendocument.text': ['.odt'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/rtf': ['.rtf'],
            'image/tiff': ['.tif', '.tiff'],
            'text/plain': ['.txt'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/zip': ['.zip'],
            'application/vnd.rar': ['.rar'],
            'application/x-7z-compressed': ['.7z'],
            'text/calendar': ['.ics']
        }
    });    

    if (organizationContext.loading) {
        return <Loader />;
    }    

    return (
        <>
            <ApiErrorMessages error={apiError} />
            <div className={getclassNames('file-upload-container', isDragActive ? 'active' : '')}>
                {lockDownFiles && <WarningMessages messages={['File Upload is disabled for the Free Plan. Upgrade to a paid plan to enable File Upload.']} />}
                {props.label && <label>{props.label}</label>}
                {!props.disableUpload && !lockDownFiles && <div {...getRootProps()} className="file-upload-zone">
                    <div className="row align-items-center">
                        <div className="column-auto">
                            <Button id={`file-upload-browse-${props.id}`} text="Browse" />
                        </div>
                        <div className="column-auto">
                            Select file{props.multiple ? '(s)' : ''} to upload.
                        </div>
                    </div>
                    <input {...getInputProps()} />
                </div>}
                {!props.disableUpload && !lockDownFiles && files.length > 0 && <hr />}
                {files.length > 0 && <div className={getclassNames('margin-top-3 files-container', files.length > 2 ? 'scroll': '')}>
                    {files.map((f, i) => {
                        const options = [];

                        if (f.status === status.UPLOADED) {
                            options.push({
                                label: 'Open',
                                onClick: () => handleOnOpen(f)
                            });
                        }

                        if (!props.disableRemove && !lockDownFiles) {
                            options.push({
                                label: 'Remove',
                                onClick: () => confirmOnRemove(f)
                            });
                        }

                        return <div key={i} className={i !== files.length - 1 ? 'border-bottom' : ''}>
                            <div className="row align-items-center justify-content-between align-items-center padding-2">
                                <div className="column">
                                    {(f.status === status.NEW || f.status === status.UPLOADING || f.status === status.STARTED) && <div className="row">
                                        <div className="column-auto">
                                            <Loader iconClassName="small" noMarginTop={true} />
                                        </div>
                                        <div className="column">
                                            <div>{f.name}</div>
                                            <div className="text-sub">{f.created && format(new Date(f.created), 'Pp')}</div>
                                        </div>
                                    </div>}
                                    {(f.status === 'Uploaded' || f.status === 'Failed' || f.status === status.SCANNING || f.status === status.INFECTED) && <div className="row">
                                        <div className="column">
                                            <div className="row">
                                                {((f.status === status.SCANNING || f.status === status.INFECTED || f.status === status.FAILED) && <div className="column-auto">
                                                    {(f.status === status.SCANNING && <span className="text-italic text-smaller">Scanning</span>) || null}
                                                    {(f.status === status.INFECTED && <span className="error-message">Infected</span>) || null}
                                                    {(f.status === status.FAILED && <span className="error-message">Failed</span>) || null}
                                                </div>) || null}
                                                <div className="column">
                                                    <div>{f.name}</div>
                                                    <div className="text-sub">{f.created && format(new Date(f.created), 'Pp')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="column-auto">
                                    <div className="row">
                                        {f.status === status.FAILED && <div className="column-auto">
                                            <Button id={`retry-upload-${f.fileId}`}
                                                onClick={() => handleOnRetry(f)}
                                                className="small secondary"
                                                text="Retry" />
                                        </div>}
                                        {options.length !== 0 && <div className="column-auto">
                                            <Dropdown id={`options-dropdown-${f.fileId!}-${i}`}
                                                options={options}
                                                openTop={i === files.length - 1 && i !== 0}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>}
                {!props.disableUpload && !lockDownFiles && <div className="text-sub">
                    Max file size of 50MB
                </div>}
                {!files.length && props.disableUpload && <div>
                    No files found
                </div>}
            </div>
            <Modal id="file-upload-delete-modal" visible={confirmRemove} className="small" title="Confirm File Remove" onClose={handleOnCancelRemoval}>
                <>
                    <div className="row">
                        <div className="column">
                            <p>Are you sure you want to remove this file?</p>
                            <p>{fileToRemove?.name}</p>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-remove" text="No" className="secondary" onClick={handleOnCancelRemoval} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-remove" text="Yes" className="warning" onClick={handleOnRemove} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>

    )
};


export default FileUpload;