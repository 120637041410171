import React, { useEffect, useState, useMemo, Fragment, FormEvent, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './ManageForms.css'
import Button from "../components/UI/Button";
import { ASSESSMENT_ID, CLIENT_ID, DELIVERY_ID, Mapping } from "../constants/formTypes";
import { format, formatDistance, parseISO } from 'date-fns';
import Loader from "../components/UI/Loader";
import { activateForm, createForm, createFormVersion, deactivateForm, deleteForm, getForms, updateForm } from "../services/formsService";
import ErrorMessages from "../components/UI/ErrorMessages";
import Form from "../models/serviceModels/Form";
import Dropdown from "../components/UI/Dropdown";
import Modal from "../components/UI/Modal";
import Input from "../components/UI/Input";
import IconButton from "../components/UI/IconButton";
import PreviewForm from "../components/Form/PreviewForm";
import AssignProgram from "../components/Programs/AssignProgram";
import { OrganizationContext } from "../App";
import { FREE_PLAN_TYPE_ID } from "../constants/planTypes";
import WarningMessages from "../components/UI/WarnginMessages";

interface DraftForm {
    name: string;
    form: Form;
}

interface FormWithDrafts {
    form: Form;
    isOnlyDraft: boolean;
    draftForms: DraftForm[];
}

interface ProgramForms {
    programId?: string;
    programName?: string;
    forms: FormWithDrafts[];
}

const ManageForms = () => {
    const [forms, setForms] = useState<Form[]>([]);
    const [loading, setLoading] = useState(true);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [creatingForm, setCreatingForm] = useState(false);
    const [includeDeactivated, setIncludeDeactivated] = useState(false);
    const [creatingNewVersion, setCreatingNewVersion] = useState(false);
    const [creatingNewVersionFormVersionId, setCreatingNewVersionFormVerionId] = useState('');
    const [assignForm, setAssignForm] = useState<Form>();
    const [creatingAssessment, setCreatingAssessment] = useState(false);
    const [draftsDisplayed, setDraftsDisplayed] = useState<Form[]>([]);
    const [assignedFormsHidden, setAssignedFormsHidden] = useState<string[]>([]);
    const [creatingDelivery, setCreatingDelivery] = useState(false);
    const [creatingClient, setCreatingClient] = useState(false);
    const [error, setError] = useState('');
    const [modalError, setModalError] = useState('');
    const [deletingForm, setDeletingForm] = useState(false);
    const [confirmFormDelete, setConfirmFormDelete] = useState<{ name: string, form: Form }>();
    const [activatingForm, setActivatingForm] = useState(false);
    const [confirmFormActivate, setConfirmFormActivate] = useState<Form>();
    const [deactivatingForm, setDeactivatingForm] = useState(false);
    const [confirmFormDeactivate, setConfirmFormDeactivate] = useState<Form>();
    const [renamingForm, setRenamingForm] = useState(false);
    const [renameForm, setRenameForm] = useState<Form>();
    const [editingFormName, setEditingFormName] = useState('');
    const [previewForm, setPreviewForm] = useState<{ form: Form, name?: string }>();
    const [namingNewForm, setNamingNewForm] = useState(false);
    const [newFormDetails, setNewFormDetails] = useState<{ formName: string, formType: 'assessment' | 'delivery' }>();
    const location = useLocation();
    const organizationContext = useContext(OrganizationContext);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Build Forms - All Forms"
    }, []);

    useEffect(() => {
        organizationContext.refresh();
    }, []);

    useEffect(() => {
        setCreatingForm(creatingAssessment || creatingDelivery || creatingClient);
    }, [creatingAssessment, creatingDelivery, creatingClient])

    const loadForms = (deactivated: boolean) => {
        setLoading(true);
        getForms(deactivated).then(forms => {
            setForms(forms);
        }).catch(e => {
            console.error(e);
            setFailedToLoad(true);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        loadForms(includeDeactivated);
    }, [includeDeactivated]);

    
    useEffect(() => {
        if (!forms || forms.length == 0 || !location.state) {
            return;
        }

        const { publishedForm } = location.state as any;
        if (publishedForm) {
            const existingForm = forms.find(f => f.id === publishedForm.id);
            if (existingForm && !existingForm.programId && existingForm.formTypeId !== CLIENT_ID) {
                // not already assigned to a program
                setAssignForm(existingForm);    
            }
           
            window.history.replaceState({}, ''); // clearing state
        }
    }, [location, forms]);


    const groupedForms: ProgramForms[] = useMemo(() => {
        const grouping: FormWithDrafts[] = [];
        if (!forms || forms.length === 0) {
            return [];
        }

        forms.sort((a, _) => {
            return a.draft ? 1 : -1;
        })

        for (const form of forms) {
            if (!form.draft) {
                grouping.push({ form, isOnlyDraft: false, draftForms: [] });
            } else {
                const matchingGroup = grouping.find(g => g.form.id === form.id);
                if (matchingGroup) {
                    matchingGroup.draftForms.push({ name: '', form });
                } else {
                    grouping.push({ form, isOnlyDraft: true, draftForms: [] });
                }
            }
        }

        grouping.sort((a, b) => {
            return parseISO(a.form.updated || a.form.created).getTime() < parseISO(b.form.updated || b.form.created).getTime() ? 1 : -1;
        });

        for (const group of grouping) {
            // first sort it by created so we can get the correct draft number
            group.draftForms.sort((a, b) => {
                return parseISO(a.form.created).getTime() < parseISO(b.form.created).getTime() ? 1 : -1;
            });

            let draftCount = 1;
            for (const draft of group.draftForms) {
                const currentDate = format(parseISO(draft.form.created), 'MM/dd/yyyy');
                draft.name = `Draft ${currentDate} - ${draftCount++}`;
            }

            // now re-sort it by updated and created to get them into the useable order
            group.draftForms.sort((a, b) => {
                return parseISO(a.form.updated || a.form.created).getTime() < parseISO(b.form.updated || b.form.created).getTime() ? 1 : -1;
            });
        }

        const programForms: ProgramForms[] = [];

        for (const formGrouping of grouping) {
            if (formGrouping.form.formTypeId === CLIENT_ID) {
                programForms.push({
                    programId: 'client_form',
                    forms: [formGrouping]
                });
            } else {
                const existingProgram = programForms.find(pf => pf.programId === formGrouping.form.programId);
                if (existingProgram) {
                    existingProgram.forms.push(formGrouping);
                } else {
                    programForms.push({
                        programId: formGrouping.form.programId,
                        programName: formGrouping.form.programName,
                        forms: [formGrouping]
                    });
                }
            }
        }

        // sort them all by program name
        programForms.sort((a, b) => !a.programName || !b.programName ? 0 : a.programName.toLowerCase().localeCompare(b.programName.toLowerCase()));
        
        // move client form to the top
        const indexOfClientForm = programForms.findIndex(pf => pf.programId === 'client_form');
        const [clientForm] = programForms.splice(indexOfClientForm, 1);
        programForms.unshift(clientForm);

        // move the unassigned forms to the top
        const indexOfUnassigned = programForms.findIndex(pf => !pf.programId);
        const [unassignedForms] = programForms.splice(indexOfUnassigned, 1);
        programForms.unshift(unassignedForms);

        return programForms;
    }, [forms]);

    const handleSettingNewFormName = (formType: 'assessment' | 'delivery') => {
        setNewFormDetails({
            formName: '',
            formType
        });

        setNamingNewForm(true);
    };

    const handleCreatingForm = (e: FormEvent) => {
        e.preventDefault();
        if (!newFormDetails) {
            return;
        }

        if (newFormDetails.formType === 'assessment') {
            handleCreateAssessment(newFormDetails.formName);
        } else if (newFormDetails.formType === 'delivery') {
            handleCreateDelivery(newFormDetails.formName);
        }
    };

    const handleCreateDelivery = (formName: string) => {
        setCreatingDelivery(true);
        setError('');

        createForm({
            name: formName,
            formTypeId: DELIVERY_ID
        }).then(form => {
            navigate(`/admin/forms/${form.id}/versions/${form.versionId}`);
        }).catch(e => {
            console.error(e);
            setError('Failed to create Outputs and Outcomes form');
        }).finally(() => {
            setNamingNewForm(false);
            setNewFormDetails(undefined);
            setCreatingDelivery(false);
        });
    };

    const handleCreateAssessment = (formName: string) => {
        setCreatingAssessment(true);
        setError('');

        createForm({
            name: formName,
            formTypeId: ASSESSMENT_ID
        }).then(form => {
            navigate(`/admin/forms/${form.id}/versions/${form.versionId}`);
        }).catch(e => {
            console.error(e);
            setError('Failed to create assessment');
        }).finally(() => {
            setNamingNewForm(false);
            setNewFormDetails(undefined);
            setCreatingAssessment(false);
        });
    };

    const handleCreateClient = () => {
        setCreatingClient(true);
        setError('');

        createForm({
            name: 'Client Additional Information',
            formTypeId: CLIENT_ID
        }).then(form => {
            navigate(`/admin/forms/${form.id}/versions/${form.versionId}`);
        }).catch((e) => {
            console.error(e);
            setError('Failed to create client form');
        }).finally(() => {
            setCreatingClient(false);
        });
    };

    const handleOnEdit = (form: Form) => {
        if (form.draft) {
            navigate(`/admin/forms/${form.id}/versions/${form.versionId}`);
        } else {
            setCreatingNewVersion(true);
            setError('');
            setCreatingNewVersionFormVerionId(form.versionId);

            createFormVersion(form.id)
                .then(result => {
                    navigate(`/admin/forms/${result.formId}/versions/${result.versionId}`);
                })
                .catch((e) => {
                    console.error(e);
                    setError('Failed to edit form');
                })
                .finally(() => {
                    setCreatingNewVersion(false);
                    setCreatingNewVersionFormVerionId('');
                });

        }
    };

    const handleOnPreview = (form: Form, name?: string) => {
        setPreviewForm({ form, name });
    };

    const handleOnView = (form: Form) => {
        navigate(`/admin/forms/${form.id}/versions/${form.versionId}`);
    };

    const handleOnAssignStart = (form: Form) => {
        setAssignForm(form);
    }

    const handleOnDelete = (form: { name: string, form: Form }) => {
        setModalError('');
        setConfirmFormDelete(form);
    };

    const handleOnActivate = (form: Form) => {
        setModalError('');
        setConfirmFormActivate(form);
    };

    const handleOnDeactivate = (form: Form) => {
        setModalError('');
        setConfirmFormDeactivate(form);
    };

    const handleOnRename = (form: Form) => {
        setModalError('');
        setEditingFormName(form.name);
        setRenameForm(form);
    };

    const handleConfirmedDelete = () => {
        if (!confirmFormDelete) {
            return;
        }
        setModalError('');
        setDeletingForm(true);
        deleteForm({
            formId: confirmFormDelete.form.id,
            versionId: confirmFormDelete.form.versionId
        }).then(() => {
            setConfirmFormDelete(undefined);
            loadForms(includeDeactivated);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to delete form');
        }).finally(() => {
            setDeletingForm(false);
            organizationContext.refresh();
        });
    };

    const handleConfirmedActivate = () => {
        if (!confirmFormActivate) {
            return;
        }
        setModalError('');
        setActivatingForm(true);
        activateForm(confirmFormActivate.id).then(() => {
            setConfirmFormActivate(undefined);
            loadForms(includeDeactivated);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to activate form');
        }).finally(() => {
            setActivatingForm(false);
            organizationContext.refresh();
        });
    };

    const handleConfirmedDeactivate = () => {
        if (!confirmFormDeactivate) {
            return;
        }
        setModalError('');
        setDeactivatingForm(true);
        deactivateForm(confirmFormDeactivate.id).then(() => {
            setConfirmFormDeactivate(undefined);
            loadForms(includeDeactivated);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to deactivate form');
        }).finally(() => {
            setDeactivatingForm(false);
            organizationContext.refresh();
        });
    };

    const handleToggleShowDrafts = (form: Form) => {
        if (draftsDisplayed.some(d => d === form)) {
            setDraftsDisplayed(dd => dd.filter(d => d !== form));
        } else {
            setDraftsDisplayed(dd => [...dd, form]);
        }
    };

    const handleToggleShowAssignedForms = (programId?: string) => {
        if (assignedFormsHidden.includes(programId ?? 'unassigned')){
            setAssignedFormsHidden([
                ...assignedFormsHidden.filter(f => f !== (programId ?? 'unassigned'))
            ]);
        } else {
            setAssignedFormsHidden([
                ...assignedFormsHidden,
                programId ?? 'unassigned'
            ]);
        }
    };

    const handleCancellingNamingNewForm = () => {
        setNamingNewForm(false);
        setNewFormDetails(undefined);
    };

    const handleSubmitRenameForm = (e: any) => {
        e.preventDefault();
        if (!renameForm) {
            return;
        }

        setModalError('');

        if (!editingFormName) {
            setModalError('Form name is required');
            return;
        }

        setRenamingForm(true);
        updateForm({
            id: renameForm.id,
            name: editingFormName
        }).then(() => {
            setRenameForm(undefined);
            loadForms(includeDeactivated);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to rename form');
        }).finally(() => {
            setRenamingForm(false);
        });
    };

    const getFormGroupingName = (program: ProgramForms) => {
        if (program.programId === 'client_form') {
            return 'Client Additional Information';
        }

        if (!program.programId) {
            return 'Not Assigned to Program';
        }

        return `Assigned to ${program.programName} Program`;
    };

    const handleOnAssign = (programId: string, programName: string, formId: string) => {
        const existingForm = forms.find(f => f.id === formId);
        if (!existingForm) {
            console.warn('Existing form not found');
            return;
        }

        existingForm.programId = programId;
        existingForm.programName = programName;

        setForms([
            ...forms
        ]);

        setAssignForm(undefined);
    };

    if (failedToLoad) {
        return (<div className="section">
            <div className="row justify-content-center">
                <div className="column-auto">
                    Failed to load...
                </div>
            </div>
        </div>);
    }

    const lockDownForms = organizationContext.value?.planTypeId === FREE_PLAN_TYPE_ID &&
        organizationContext.value?.activeFormCount >= 5;

    return (<section id="main-content" tabIndex={-1}>
        <h1>Forms</h1>   
        {error && <ErrorMessages messages={[error]} />}     
        {lockDownForms && <WarningMessages messages={['Only 5 forms are allowed to be active at once on the Free Plan. Upgrade to a paid plan or deactivate existing forms to create a new form.']} />}
        <div className="row justify-content-between align-items-center margin-bottom-3">
            <div className="column-auto">
                <div>
                    <Button type="button" 
                        id="create-assessment-button" 
                        onClick={() => handleSettingNewFormName('assessment')} 
                        text="Create Assessment" 
                        loading={creatingAssessment} 
                        disabled={creatingForm || lockDownForms} />
                    {forms && !loading && !forms.some(f => f.formTypeId === CLIENT_ID) && (
                        <Button type="button"
                            className="margin-start-1"
                            id="create-client-button"
                            onClick={handleCreateClient}
                            text="Create Profile"
                            loading={creatingClient}
                            disabled={creatingForm} />)}
                    <Button type="button"
                        className="margin-start-1"
                        id="create-delivery-button"
                        onClick={() => handleSettingNewFormName('delivery')}
                        text="Create Outputs and Outcomes"
                        loading={creatingDelivery}
                        disabled={creatingForm || lockDownForms} />
                </div>
            </div>
            <div className="column-auto">
                <div className="row align-items-center">
                    <div className="column-auto">
                        <label htmlFor="include-inactive">Include Inactive Forms</label>
                    </div>
                    <div className="column-auto">
                        <input type="checkbox" 
                            id="include-inactive" 
                            checked={includeDeactivated} 
                            onChange={(e) => setIncludeDeactivated(e.target.checked)} />
                    </div>
                </div>
            </div>
        </div>
        {loading && <Loader />}
        {!loading && (<>
            {(!groupedForms || groupedForms.length === 0) && <div>You haven't created any forms yet.  Select Create Delivery or Create Assessment to begin.</div>}
            {groupedForms && groupedForms.length > 0 && (<>
                {groupedForms.map(gf => {
                    return <Fragment key={gf.programId}>
                        <h2 className="margin-vertical-5 program-name"><IconButton id={`hide-show-assigned-forms-${gf.programId}`}
                                onClick={() => handleToggleShowAssignedForms(gf.programId)}
                                className="small flat margin-end-5"
                                icon={assignedFormsHidden.includes(gf.programId ?? 'unassigned') ? <i className="ri-arrow-down-s-line"></i> : <i className="ri-arrow-up-s-line"></i>} />
                                {getFormGroupingName(gf)}</h2>
                        {!assignedFormsHidden.includes(gf.programId ?? 'unassigned') && <div className="list-item-container">
                            {gf.forms.map(f => {
                                const options = [];

                                if (!f.isOnlyDraft) {
                                    options.push({ label: 'View', onClick: () => handleOnView(f.form) });
                                    if (f.form.formTypeId !== CLIENT_ID) {
                                        options.push({ label: 'Assign to Program', onClick: () => handleOnAssignStart(f.form) });
                                    }
                                } else {
                                    options.push({label: 'Delete', onClick: () => handleOnDelete({name: f.form.name, form: f.form})});
                                }

                                options.push({ label: 'Edit', onClick: () => handleOnEdit(f.form) });
                                options.push({ label: 'Preview', onClick: () => { handleOnPreview(f.form) } });

                                if (f.form.formTypeId !== CLIENT_ID && !f.isOnlyDraft) {
                                    if (!f.form.deactivated) {
                                        options.push({ label: 'Deactivate', onClick: () => handleOnDeactivate(f.form) });
                                    }
                                    if (f.form.deactivated && !lockDownForms) {
                                        options.push({ label: 'Activate', onClick: () => handleOnActivate(f.form) });
                                    }
                                }

                                options.push({ label: 'Rename', onClick: () => handleOnRename(f.form) });

                                return (
                                    <Fragment key={f.form.id}>
                                        <div className="list-item form-list-item">
                                            <div className="row justify-content-between">
                                                <div className="column-1">
                                                    <IconButton id={`hide-show-drafts-${f.form.id}`}
                                                        onClick={() => handleToggleShowDrafts(f.form)}
                                                        disabled={f.draftForms.length === 0}
                                                        className="small flat"
                                                        icon={draftsDisplayed.includes(f.form) ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>} />
                                                </div>
                                                <div className="column-8">
                                                    <div className="row">
                                                        <div className="column">
                                                            {f.form.name} - {Mapping[f.form.formTypeId]}
                                                            {f.isOnlyDraft && <> - <span className="text-bold text-italic">Draft</span></>}
                                                            {f.form.deactivated && <> - <span className="text-bold text-italic">Inactive</span></>}
                                                            <div className="text-sub">Modified {formatDistance(parseISO(f.form.updated || f.form.created), new Date(), { addSuffix: true })}</div>
                                                            {!f.isOnlyDraft && <div className="text-sub">{f.draftForms.length} Draft(s)</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="row justify-content-end">
                                                        <div className="column-auto">
                                                            <Dropdown id={`options-dropdown-${f.form.versionId}`}
                                                                options={options}
                                                                optionSize="large"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {draftsDisplayed.includes(f.form) && f.draftForms.map(draft => {

                                            const draftOptions = [
                                                { label: 'Edit', onClick: () => handleOnEdit(draft.form) },
                                                { label: 'Delete', onClick: () => handleOnDelete(draft) },
                                                { label: 'Preview', onClick: () => { handleOnPreview(draft.form, draft.name) } }
                                            ];

                                            return (<Fragment key={draft.form.id}>
                                                <div className="list-item form-list-item sub-list-item">
                                                    <div className="row  align-items-center">
                                                        <div className="column-8">
                                                            <div className="row">
                                                                <div className="column">
                                                                    <div className="margin-start-2 text-italic">{draft.name}</div>
                                                                    <div className="text-sub">Modified {formatDistance(parseISO(draft.form.updated || draft.form.created), new Date(), { addSuffix: true })}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column">
                                                            <div className="row justify-content-end">
                                                                <div className="column-auto">
                                                                    <Dropdown id={`options-dropdown-${draft.form.versionId}`}
                                                                        options={draftOptions}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>);
                                        })}
                                    </Fragment>
                                );
                            })}
                        </div>}
                    </Fragment>
                })}
            </>)}
        </>)}
        <Modal id="delete-modal" visible={confirmFormDelete !== undefined} className="small" onClose={() => setConfirmFormDelete(undefined)} title="Confirm Delete Form">
            {confirmFormDelete && (
                <div>
                    {modalError && <ErrorMessages messages={[modalError]} />}
                    <div className="row">
                        <div className="column">
                            Are you sure you want to delete this form?
                            <br />
                            "{confirmFormDelete.name}"
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-delete"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={deletingForm}
                                        onClick={() => setConfirmFormDelete(undefined)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-delete"
                                        text="Delete Form"
                                        className="warning"
                                        disabled={deletingForm}
                                        loading={deletingForm}
                                        onClick={handleConfirmedDelete} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
        <Modal id="activate-modal" visible={confirmFormActivate !== undefined} className="small" onClose={() => setConfirmFormActivate(undefined)} title="Activate Form">
            {confirmFormActivate && (
                <>
                    {modalError && <ErrorMessages messages={[modalError]} />}
                    <div className="row">
                        <div className="column">
                            <p>Are you sure you want to activate this form?
                                (Users will be able to start submitting this form)
                            </p>
                            <p>
                                "{confirmFormActivate.name}"
                            </p>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-activate"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={activatingForm}
                                        onClick={() => setConfirmFormActivate(undefined)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-activate"
                                        text="Activate"
                                        disabled={activatingForm}
                                        loading={activatingForm}
                                        onClick={handleConfirmedActivate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Modal>
        <Modal id="deactivate-modal" visible={confirmFormDeactivate !== undefined} className="small" onClose={() => setConfirmFormDeactivate(undefined)} title="Deacivate Form">
            {confirmFormDeactivate && (
                <>
                    {modalError && <ErrorMessages messages={[modalError]} />}
                    <div className="row">
                        <div className="column">
                            <p>Are you sure you want to deactivate this form?
                                (Users will NO longer be able to submit the form. Any form in progress might fail to submit.)
                            </p>
                            <p>
                                "{confirmFormDeactivate.name}"
                            </p>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-deactivate"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={deactivatingForm}
                                        onClick={() => setConfirmFormDeactivate(undefined)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-deactivate"
                                        text="Deactivate"
                                        disabled={deactivatingForm}
                                        loading={deactivatingForm}
                                        onClick={handleConfirmedDeactivate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Modal>
        <Modal id="rename-modal" visible={renameForm !== undefined} className="small" onClose={() => setRenameForm(undefined)} title="Rename Form">
            {renameForm && (
                <form onSubmit={handleSubmitRenameForm}>
                    {modalError && <ErrorMessages messages={[modalError]} />}
                    <div className="row">
                        <div className="column">
                            <label>Current Name:</label>
                            <br />
                            {renameForm.name}
                        </div>
                    </div>
                    <div className="row margin-top-2">
                        <div className="column">
                            <Input id="new-form-name" label="New Name" value={editingFormName} onChange={(e) => setEditingFormName(e)} />
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-rename"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={renamingForm}
                                        onClick={() => setRenameForm(undefined)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="submit-rename"
                                        text="Rename"
                                        type="submit"
                                        disabled={renamingForm}
                                        loading={renamingForm} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Modal>
        <Modal id="preview-form-modal" visible={previewForm !== undefined} className="medium" onClose={() => setPreviewForm(undefined)} title="Preview Form">
            {previewForm && <div className="row justify-content-center">
                <div className="column-auto">
                    {previewForm.name && <i>{previewForm.name}</i>}
                    <PreviewForm formId={previewForm.form.id}
                        versionId={previewForm.form.versionId} />
                </div>
            </div>}
        </Modal>
        <Modal id="assign-to-program-modal" visible={assignForm !== undefined} className="medium" onClose={() => setAssignForm(undefined)} title="Assign Form to Program">
            {assignForm && <div className="row justify-content-center">
                <div className="column">
                    {assignForm.name && <div className="margin-vertical-5">Form Name: <i>{assignForm.name}</i></div>}
                    <AssignProgram formId={assignForm.id}
                        onAssigned={(id, name) => handleOnAssign(id, name, assignForm.id)} />
                </div>
            </div>}
        </Modal>
        <Modal id="new-form-name" visible={namingNewForm} className="small" onClose={() => setNamingNewForm(false)} title="Name New Form">
            {newFormDetails && <form onSubmit={handleCreatingForm}>
                {modalError && <ErrorMessages messages={[modalError]} />}
                <div className="row margin-top-2">
                    <div className="column">
                        <Input id="new-form-name"
                            value={newFormDetails.formName}
                            onChange={(value) => setNewFormDetails({ ...newFormDetails, formName: value })}
                            label={`New ${newFormDetails.formType === 'assessment' ? 'Assessment' : 'Outputs and Outcomes'} Name`} />
                    </div>
                </div>
                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-rename"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={creatingForm}
                                    onClick={handleCancellingNamingNewForm} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="submit-rename"
                                    text="Create Form"
                                    type="submit"
                                    disabled={creatingForm}
                                    loading={creatingForm} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>}
        </Modal>
    </section>);
};

export default ManageForms;