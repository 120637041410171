import axios from "axios";
import Organization from "../models/serviceModels/Organization";
import UpdateOrganizationRequest from "../models/serviceModels/UpdateOrganizationRequest";
import CreateOrganizationPlanRequest from "../models/serviceModels/CreateOrganizationPlanRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getOrganization = async (): Promise<Organization> => {
    const result = await axios.get(`${baseUrl}/organizations`);
    return result.data;
};

const updateOrganization = async (request: UpdateOrganizationRequest): Promise<Organization> => {
    const result = await axios.put(`${baseUrl}/organizations`, request);
    return result.data;
};

const answerTermsOfService = async (): Promise<Organization> => {
    const result = await axios.post(`${baseUrl}/organizations/tos`, null);
    return result.data;
};

const createOrganizationPlan = async (request: CreateOrganizationPlanRequest): Promise<void> => {
    await axios.post(`${baseUrl}/organizations/plan`, request)
}

export {
    getOrganization,
    updateOrganization,
    answerTermsOfService,
    createOrganizationPlan
};