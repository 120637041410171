import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import Protect from "../components/Layout/Protect";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Loader from "../components/UI/Loader";
import Select from "../components/UI/Select";
import { ASSESSMENT_ID, DELIVERY_ID, Mapping } from "../constants/formTypes";
import useElementVisible from "../hooks/useElementVisible";
import Form from "../models/serviceModels/Form";
import FormInstance from "../models/serviceModels/FormInstance";
import { deleteFormInstance, searchFormsInstances } from "../services/formsService";
import { formatJSONDateWithoutTime } from "../utils/dateHelpers";
import { useParams } from "react-router-dom";
import { ACTIVITY_DATE_ID } from "../constants/fieldTypes";
import { executeSearch, getDefaultSearchFilters, getSearchFields } from "../services/searchService";
import { TRACKING_HISTORY_SEARCH } from "../constants/searchType";
import SearchFieldReference from "../models/serviceModels/SearchFieldReference";
import DefaultSearchFilter from "../models/serviceModels/DeafultSearchFilter";
import Search, { SearchEvent } from "../components/UI/Search";
import { format } from "date-fns";
import { MeContext } from "../App";
import Modal from "../components/UI/Modal";
import ErrorMessages from "../components/UI/ErrorMessages";
import Dropdown from "../components/UI/Dropdown";
import { ADMIN_ROLE_ID, CONTRIBUTOR_ROLE_ID } from "../constants/userRoles";
import { deleteFormInstancesEnabled } from "../utils/features";

const TAKE = 10;

const ParticipantTrackingHistory = () => {
    const { id } = useParams();
    const [paging, setPaging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState<Form[]>();
    const [instances, setInstances] = useState<FormInstance[]>();
    const [totalInstances, setTotalInstances] = useState<number>();
    const [skip, setSkip] = useState<number>(0);
    const [apiErrors, setApiErrors] = useState<any>();
    const [formTypeId, setFormTypeId] = useState('');
    const [formId, setFormId] = useState('');
    const [searching, setSearching] = useState(false);
    const loaded = useRef(false);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [filteredForms, setFilteredForms] = useState<Form[]>([]);
    const [searchFields, setSearchFields] = useState<SearchFieldReference[]>([]);
    const [searchFilters, setSearchFilters] = useState<DefaultSearchFilter[]>([]);
    const [searchEvent, setSearchEvent] = useState<SearchEvent>();
    const [confirmInstanceDeletion, setConfirmInstanceDeletion] = useState<FormInstance>();
    const [modalErrors, setModalErrors] = useState<any>();
    const [deleting, setDeleting] = useState(false);
    const me = useContext(MeContext);

    useEffect(() => {
        setLoading(true);
        if (id) {
            Promise.all([executeSearch<FormInstance>({
                searchTypeId: TRACKING_HISTORY_SEARCH,
                fields: [],
                disabledFilters: [],
                skip,
                clientId: id,
                take: TAKE
            }), getSearchFields(TRACKING_HISTORY_SEARCH, undefined, true), getDefaultSearchFilters(TRACKING_HISTORY_SEARCH)]).then(([fo, fi, df]) => {
                setSearchFields(fi);
                setSearchFilters(df);
                setInstances(fo.data);
                setTotalInstances(fo.totalCount);
                loaded.current = true;
            }).catch(e => {
                console.error(e);
                setApiErrors(e);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [id]);

    useEffect(() => {
        setFormId('');

        if (!forms || !formTypeId || isNaN(Number(formTypeId))) {
            return;
        }

        setFilteredForms([
            ...forms.filter(f => f.formTypeId === Number(formTypeId))
        ]);
    }, [formTypeId, forms]);

    useEffect(() => {
        if (visible) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible]);

    const executeFormSearch = useCallback((request: { skip: number, formTypeId?: string, formId?: string }) => {
        setApiErrors(undefined);
        if (!id) {
            return new Promise<any>((_, reject) => {
                reject('Cannot execute search without a participant ID')
            });
        }

        return searchFormsInstances({
            skip: request.skip,
            formTypeId: request.formTypeId && !isNaN(Number(request.formTypeId)) ? Number(request.formTypeId) : undefined,
            formId: request.formId,
            clientId: id
        });
    }, [id]);

    useEffect(() => {
        if (!loaded.current) {
            return;
        }

        setPaging(true);

        executeSearch<FormInstance>({
            fields: searchEvent?.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: searchEvent?.disabledFilters || [],
            skip: skip,
            take: TAKE,
            clientId: id,
            searchTypeId: TRACKING_HISTORY_SEARCH,
            query: searchEvent?.query || ''
        }).then(r => {
            setInstances(current => {
                return current ? [
                    ...current,
                    ...r.data
                ] : r.data;
            });
            setTotalInstances(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip, executeFormSearch]);

    const handleOnSearchV2 = (e: SearchEvent) => {
        setSkip(0);
        setSearching(true);
        setSearchEvent(e);
        setApiErrors(undefined);
        console.log('Search Event', e);

        executeSearch<FormInstance>({
            fields: e.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: e.disabledFilters,
            skip: 0,
            take: TAKE,
            clientId: id,
            searchTypeId: TRACKING_HISTORY_SEARCH,
            query: e.query
        }).then(r => {
            setInstances(r.data);
            setTotalInstances(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const handleDeletion = () => {
        if (!confirmInstanceDeletion) {
            return;
        }

        setDeleting(true);

        deleteFormInstance(confirmInstanceDeletion.formId, confirmInstanceDeletion.id).then(() => {
            setConfirmInstanceDeletion(undefined);
            handleOnSearchV2(searchEvent || {
                fields: [],
                disabledFilters: []
            });
        }).catch(e => {
            console.error(e);
            setModalErrors(e);
        }).finally(() => {
            setDeleting(false);
        });
    };

    const handleConfirmDeletion = (instance: FormInstance) => {
        setConfirmInstanceDeletion(instance);
        setModalErrors(undefined);
        setDeleting(false);
    };

    const getMainContent = () => {
        if (loading) {
            return <Loader />
        }

        const getActivityDate = (instance: FormInstance) => {
            if (instance.activityDate) {
                return instance.activityDate;
            }

            const activityDateField = instance.instanceFields?.find(fi => fi.fieldTypeId === ACTIVITY_DATE_ID);
            if (!activityDateField) {
                return null;
            }

            return activityDateField.value;
        };

        return (
            <>
                <h3>Tracking History</h3>
                <ApiErrorMessages error={apiErrors} />
                {instances && (<>
                    <Search
                        id={`search-tracking-history`}
                        searching={searching}
                        fields={searchFields}
                        defaultFilters={searchFilters}
                        onSearch={handleOnSearchV2}
                        lockedOpen={true} />
                    <div className="list-item-container">
                        {instances.map(i => {
                            const options: { label: string, href?: string, onClick?: () => void, newWindow?: boolean; }[] = [
                                {
                                    label: 'View',
                                    href: `/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/view`
                                }
                            ];
                            if (me.user?.userRoleId === ADMIN_ROLE_ID || me.user?.userRoleId === CONTRIBUTOR_ROLE_ID) {
                                options.push({
                                    label: 'Edit',
                                    href: `/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/edit`
                                });
                                if (deleteFormInstancesEnabled) {
                                    options.push({
                                        label: 'Delete',
                                        onClick: () => handleConfirmDeletion(i)
                                    });
                                }
                            }
                            const activityDate = getActivityDate(i);
                            return (<div className="list-item">
                                <div className="row align-items-center justify-content-between">
                                    <div className="column-medium-4">
                                        <div><strong>{i.formName}</strong></div>
                                        <div>{Mapping[i.formTypeId]}</div>
                                    </div>
                                    <div className="column-medium-3">
                                        <div><small>Originally Submitted: {format(new Date(i.created), 'MM/dd/yyyy')}</small></div>
                                        {i.updated && <div><small>Last Updated: {i.updated && format(new Date(i.updated), 'MM/dd/yyyy')}</small></div>}
                                        {activityDate && <div><small>Activity Date: {formatJSONDateWithoutTime(activityDate, 'MM/dd/yyyy')}</small></div>}
                                    </div>
                                    <div className="column">
                                        <div className="row justify-content-end">
                                            <div className="column-auto">
                                                <Dropdown id={`dropdown-instances-${i.id}`} 
                                                    options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>);

                        })}
                    </div>
                    {paging && <Loader />}
                    {instances && totalInstances && instances.length < totalInstances && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
                </>)
                }
                <Modal id="delete-modal" visible={confirmInstanceDeletion !== undefined} className="small" title="Confirm Delete Form Submission" onClose={() => setConfirmInstanceDeletion(undefined)}>
                    {confirmInstanceDeletion && <>
                        <ApiErrorMessages error={modalErrors} />
                        <div className="row">
                            <div className="column">
                                <div>Are you sure you want to delete this Form Submission?</div>
                                <br/>
                                <div>This action is irreversible.</div>
                                <br/>
                                <div>Form Name: {confirmInstanceDeletion.formName}</div>
                                <div>Activity Date: {(confirmInstanceDeletion.activityDate && ` - ${formatJSONDateWithoutTime(confirmInstanceDeletion.activityDate, 'MM/dd/yyyy')}`) || 'Not Set'}</div>
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-auto">
                                        <Button id="cancel-delete"
                                            text="Cancel" 
                                            className="secondary" 
                                            disabled={deleting} 
                                            onClick={() => setConfirmInstanceDeletion(undefined)} />
                                    </div>
                                    <div className="column-auto">
                                        <Button id="execute-delete"
                                            text="Delete"
                                            className="warning"
                                            disabled={deleting}
                                            loading={deleting}
                                            onClick={handleDeletion} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </Modal>
            </>            
        );
    };

    return getMainContent();
};

export default ParticipantTrackingHistory;
