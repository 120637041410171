import { useContext, useEffect, useMemo, useState } from 'react';
import UsageMetrics from '../models/serviceModels/UsageMetrics';
import { getUsageMetrics } from '../services/quotaService';
import { QuotasModel, UserDataBreakdown } from '../models/pageModels/QuotasModel';
import { ADVANCED_REPORTS_TODAY, BASIC_REPORTS_TODAY, FILE_DATA, FORM_DATA, FORM_SUBMISSIONS_THIS_MONTH, FORMS } from '../constants/usageMetricType';
import { OrganizationContext } from '../App';
import { FREE_PLAN_TYPE_ID } from '../constants/planTypes';

const useQuotas = (): QuotasModel => {
    const [usageMetrics, setUsageMetrics] = useState<UsageMetrics[]>();
    const [loading, setLoading] = useState(false);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const organizationContext = useContext(OrganizationContext);

    useEffect(() => {
        setLoading(true);
        setFailedToLoad(false);
        getUsageMetrics().then((m) => {
            setUsageMetrics(m);
        }).catch(e => {
            console.error(e);
            setFailedToLoad(true);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const userBasicReportsToday = useMemo(() => {
        const counts: { id: string, name: string, total: number }[] = [];
        if (!usageMetrics) {
            return counts;
        }

        for (const metric of usageMetrics.filter(m => m.type === BASIC_REPORTS_TODAY && m.userId)) {
            const existing = counts.find(c => c.id === metric.userId);
            if (!existing) {
                counts.push({
                    id: metric.userId!,
                    name: `${metric.userFirstName} ${metric.userLastName}`,
                    total: metric.count
                });
            } else {
                existing.total += metric.count;
            }
        }

        return counts;
    }, [usageMetrics]);

    const userAdvancedReportsToday = useMemo(() => {
        const counts: { id: string, name: string, total: number }[] = [];
        if (!usageMetrics) {
            return counts;
        }

        for (const metric of usageMetrics.filter(m => m.type === ADVANCED_REPORTS_TODAY && m.userId)) {
            const existing = counts.find(c => c.id === metric.userId);
            if (!existing) {
                counts.push({
                    id: metric.userId!,
                    name: `${metric.userFirstName} ${metric.userLastName}`,
                    total: metric.count
                });
            } else {
                existing.total += metric.count;
            }
        }

        return counts;
    }, [usageMetrics]);

    
    const totalFormDataUsage = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        const totalFormSize = usageMetrics.filter(m => m.type === FORM_DATA).reduce((pv, cv) => pv + Number(cv.count), 0);

        return totalFormSize / 1024.0 / 1024;
    }, [usageMetrics]);

    
    const totalFileDataUsage = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        const totalFileSize = usageMetrics.filter(m => m.type === FILE_DATA).reduce((pv, cv) => pv + Number(cv.count), 0);

        return totalFileSize / 1024.0 / 1024;
    }, [usageMetrics]);

    const totalFormCounts = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        return usageMetrics.filter(m => m.type === FORMS).reduce((pv, cv) => pv + Number(cv.count), 0);
    }, [usageMetrics]);

    const totalSubmissionsThisMonth = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        return usageMetrics.filter(m => m.type === FORM_SUBMISSIONS_THIS_MONTH).reduce((pv, cv) => pv + Number(cv.count), 0);
    }, [usageMetrics]);

    const userDataUsage = useMemo(() => {
        const totalData: UserDataBreakdown[] = [];
        if (!usageMetrics) {
            return totalData;
        }

        for (const metric of usageMetrics.filter(m => m.type === FORM_DATA || m.type === FILE_DATA)) {
            if (!metric.userId) {
                continue;
            }

            let existing = totalData.find(c => c.id === metric.userId);
            if (!existing) {
                existing = {
                    id: metric.userId,
                    name: `${metric.userFirstName} ${metric.userLastName}`,
                    totalFormData: 0,
                    totalFileData: 0
                }
                totalData.push(existing);
            }

            if (metric.type === FILE_DATA) {
                existing.totalFileData += Number(metric.count) / 1024.0 / 1024;
            } else {
                existing.totalFormData += Number(metric.count) / 1024.0 / 1024;
            }
        }

        return totalData;
    }, [usageMetrics]);

    const handleRefresh = () => {
        organizationContext.refresh();
        getUsageMetrics().then((m) => {
            setUsageMetrics(m);
        }).catch(e => {
            console.error(e);
        });
    };

    const freePlanLimits = {
        maxFormData: 100,
        maxFileData: null,
        maxForms: 5,
        maxUsers: 2,
        maxParticipants: 250,
        maxFormSubmissionsPerMonth: 250,
        maxCasePlans: 250,
        basicReportsPerDay: 10,
        advancedReportsPerDay: null
    };

    const standardPlanLimits = {
        maxFormData: 1024 * (organizationContext.value?.activeUserCount || 1),
        maxFileData: 3072 * (organizationContext.value?.activeUserCount || 1),
        maxForms: -1,
        maxUsers: -1,
        maxParticipants: -1,
        maxFormSubmissionsPerMonth: -1,
        maxCasePlans: -1,
        basicReportsPerDay: -1,
        advancedReportsPerDay: -1
    }

    return {
        loading, 
        failedToLoad,
        refresh: handleRefresh,
        metrics: {
            userDataUsage,
            submittedBasicReportsToday: Number(userBasicReportsToday.reduce((p, c) => p + c.total, 0)),
            submittedAdvancedReportsToday: Number(userAdvancedReportsToday.reduce((p, c) => p + c.total, 0)),
            formSubmissionsThisMonth: totalSubmissionsThisMonth,
            totalFileDataUsage: totalFileDataUsage,
            totalFormCounts,
            totalFormDataUsage,
            totalActiveParticipants: organizationContext.value?.activeParticipantCount || 0,
            totalCasePlans: organizationContext.value?.activeCasePlanCount || 0,
            userSubmittedBasicReports: userBasicReportsToday,
            userSubmittedAdvancedReports: userAdvancedReportsToday
        },
        limits: !organizationContext.value ? null : organizationContext.value.planTypeId === FREE_PLAN_TYPE_ID ? freePlanLimits : standardPlanLimits
    }
};

export default useQuotas;