import { useState, useEffect, FormEvent, KeyboardEvent, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientCasePlan from "../models/serviceModels/ClientCasePlan";
import { createCasePlanGoal, createPlanNote, deleteCasePlan, deleteCasePlanGoal, deletePlanNote, getCasePlan, getCasePlanGoalStatuses, startCasePlan, stopCasePlan, updateCasePlan, updateCasePlanGoal, updatePlanNote } from "../services/clientsService";
import Loader from "../components/UI/Loader";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import { formatJSONDateWithoutTime } from "../utils/dateHelpers";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import CasePlanGoal from "../models/serviceModels/CasePlanGoal";
import Modal from "../components/UI/Modal";
import CasePlanGoalStatus from "../models/serviceModels/CasePlanGoalStatus";
import ParticipantCasePlanGoal from "../components/UI/ParticipantCasePlanGoal";
import getclassNames from "../utils/getClassNames";
import TruncatedText from "../components/UI/TruncatedText";
import Dropdown from "../components/UI/Dropdown";
import { format } from "date-fns";
import FileUpload from "../components/UI/FileUpload";
import ErrorMessages from "../components/UI/ErrorMessages";
import Input from "../components/UI/Input";
import { PlanNote } from "../models/serviceModels/PlanNote";
import { mapToUploadableFiles } from "../mappers/fileMapper";
import UploadableFile from "../models/pageModels/UploadableFile";
import { UPLOADED } from "../constants/fileUploadStatus";
import ValidatedInput from "../components/UI/ValidatedInput";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ParticipantCasePlan = () => {
    const { id, casePlanId } = useParams();
    const [plan, setPlan] = useState<ClientCasePlan>();
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [addingGoal, setAddingGoal] = useState(false);
    const [goalStatuses, setGoalStatuses] = useState<CasePlanGoalStatus[]>([]);
    const [deletingGoal, setDeletingGoal] = useState<CasePlanGoal>();
    const [modalApiError, setModalApiError] = useState<any>();
    const [deleting, setDeleting] = useState(false);
    const [recentlyCreatedGoal, setRecentlyCreatedGoal] = useState<CasePlanGoal>();
    const [recentlyChangedNote, setRecentlyChangedNote] = useState<string>();
    const [editingNoteId, setEditingNoteId] = useState<string>();
    const [editingNoteFiles, setEditingNoteFiles] = useState<UploadableFile[]>([]);
    const [noteErrorMessages, setNoteErrorMessages] = useState<string[]>([]);
    const [updatedNote, setUpdatedNote] = useState<string>('');
    const [deletingNote, setDeletingNote] = useState<PlanNote>();
    const [savingNote, setSavingNote] = useState(false);
    const [executingDeleteNote, setExecutingDeleteNote] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [newNoteFiles, setNewNoteFiles] = useState<UploadableFile[]>([]);
    const [addingNote, setAddingNote] = useState(false);
    const [showingNotes, setShowingNotes] = useState(false);
    const [newPlanName, setNewPlanName] = useState('');
    const [newPlanStartDate, setNewPlanStartDate] = useState('');
    const [savingPlan, setSavingPlan] = useState(false);
    const [editingPlan, setEditingPlan] = useState(false);
    const [newPlanNameMessages, setNewPlanNameMessages] = useState<string[]>([]);
    const [deletingPlan, setDeletingPlan] = useState(false);
    const [confirmDeletingPlan, setConfirmDeletingPlan] = useState(false);
    const [closingPlan, setClosingPlan] = useState(false);
    const [confirmClosingPlan, setConfirmClosingPlan] = useState(false);
    const [savingReorder, setSavingReorder] = useState(false);
    const [reordering, setReordering] = useState(false);
    const navigate = useNavigate();
    const [goalReordering, setGoalReordering] = useState<string>();
    const firstGoalRef = useRef<any>();
    const [stoppedDate, setStoppedDate] = useState<string>('');
    const [modalValidationError, setModalValidationError] = useState<string>();
    const [stopped, setStopped] = useState(false);
    const [reopening, setReopening] = useState(false);
    const [reopenDate, setReopenDate] = useState<string>();
    const [confirmReopening, setConfirmReopening] = useState(false);

    useEffect(() => {
        if (!id || !casePlanId) {
            return;
        }

        setLoading(true);

        Promise.all([getCasePlan({
            clientId: id,
            casePlanId
        }), getCasePlanGoalStatuses()]).then(([planResult, statusResult]) => {
            setPlan(planResult);
            setGoalStatuses(statusResult);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setStopped(plan?.stopped !== null && plan?.stopped !== undefined);
    }, [plan]);

    const handleDeleteCancel = () => {
        setModalApiError(undefined);
        setDeleting(false);
        setDeletingGoal(undefined);
    };

    const handleOnDelete = () => {
        if (!id || !casePlanId || !deletingGoal) {
            return;
        }

        setModalApiError(undefined);
        setDeleting(true);

        deleteCasePlanGoal({
            casePlanId,
            clientId: id,
            goalId: deletingGoal.id
        }).then(() => {
            setPlan(p => {
                if (!p) {
                    return p;
                }

                p.goals = p.goals!.filter(g => g.id !== deletingGoal.id);

                return {
                    ...p
                };
            });

            handleDeleteCancel();
        }).finally(() => {
            setDeleting(false);
        });
    };

    const handleOnDeleteGoal = (goal: CasePlanGoal) => {
        setDeletingGoal(goal);
    };

    const handleOnAddGoal = () => {
        if (!id || !casePlanId) {
            return;
        }

        setApiError(undefined);
        setAddingGoal(true);

        createCasePlanGoal({
            name: '',
            casePlanId,
            clientId: id
        }).then(r => {
            setRecentlyCreatedGoal(r);
            setPlan(p => {
                if (!p) {
                    return p;
                }

                return {
                    ...p,
                    goals: !p.goals ? [r] : [
                        ...p.goals,
                        r
                    ]
                }
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setAddingGoal(false);
        });
    };

    const handleEditingNote = (note: PlanNote) => {
        setEditingNoteId(note.id);
        setEditingNoteFiles(mapToUploadableFiles(note.files || []));
        setUpdatedNote(note.text);
    };

    const handleEditingOnCancel = () => {
        setEditingNoteId('');
        setEditingNoteFiles([]);
        setUpdatedNote('');
    };

    const handleConfirmDelete = (note: PlanNote) => {
        setModalApiError(undefined);
        setApiError(undefined);
        setDeletingNote(note);
    };

    useEffect(() => {
        if (reordering) {
            firstGoalRef.current?.focus();
        }
    }, [reordering]);

    useEffect(() => {
        if (!plan?.goals || plan.goals.length === 0 || !casePlanId || !id) {
            return;
        }

        const outOrderGoals = [];
        for (let i = 0; i < plan.goals.length; i++) {
            const goal = plan.goals[i];
            if (goal.order !== (i + 1)) {
                outOrderGoals.push({ goal, newOrder: i + 1 });
            }
        }

        if (outOrderGoals.length !== 0) {
            setSavingReorder(true);
            Promise.all(outOrderGoals.map(og => updateCasePlanGoal({
                casePlanId,
                clientId: id,
                name: og.goal.name,
                goalId: og.goal.id,
                order: og.newOrder,
                dueDate: og.goal.dueDate
            }))).then((updatedGoals) => {
                setPlan(p => {
                    if (!p?.goals || p.goals.length === 0) {
                        return;
                    }

                    for (const ug of updatedGoals) {
                        const existingGoal = p.goals.find(g => g.id === ug.id);
                        if (existingGoal) {
                            existingGoal.updated = ug.updated;
                            existingGoal.updatedBy = ug.updatedBy;
                            existingGoal.order = ug.order;
                        }
                    }

                    return {
                        ...p,
                        goals: [
                            ...p.goals
                        ]
                    };
                })
            }).catch(e => {
                console.error(e);
                setApiError(e);
            }).finally(() => {
                setSavingReorder(false);
            });
        }
    }, [plan]);

    useEffect(() => {
        if (!recentlyChangedNote) {
            return;
        }

        const timeoutHandle = setTimeout(() => {
            setRecentlyChangedNote(undefined);
        }, 3000);

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        }
    }, [recentlyChangedNote]);

    const handleCancellingReopen = () => {
        setReopening(false);
        setConfirmReopening(false);
        setReopenDate(undefined);
        setModalApiError(undefined);
        setModalValidationError(undefined);
    }

    const handleOnReopen = (e: FormEvent) => {
        e.preventDefault();

        if (!casePlanId || !id) {
            return;
        }

        setModalApiError(undefined);
        setModalValidationError(undefined);

        if (!reopenDate) {
            setModalValidationError('Start date is required');
            return;
        }

        setReopening(true);

        startCasePlan({
            clientId: id,
            casePlanId,
            started: reopenDate
        }).then(() => {
            if (plan) {
                plan.computedStatusName = 'In Progress';
                plan.started = reopenDate;
                plan.stopped = undefined;

                setPlan({
                    ...plan
                });
            }

            handleCancellingReopen();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setReopening(false);
        });
    };

    const handleOnDeleteNote = () => {
        if (!id || !deletingNote || !casePlanId) {
            return;
        }

        setModalApiError(undefined);
        setExecutingDeleteNote(true);

        deletePlanNote({
            clientId: id,
            noteId: deletingNote.id,
            casePlanId
        }).then(() => {
            setPlan(p => {
                if (!p) {
                    return p;
                }
                return {
                    ...p,
                    notes: (p.notes && p.notes.filter(n => n.id !== deletingNote.id)) || []
                }
            });

            handleCancellingDelete();
        }).catch(e => {
            setModalApiError(e);
            console.error(e);
        }).finally(() => {
            setExecutingDeleteNote(false);
        });
    };

    const handleCancellingDelete = () => {
        setDeletingNote(undefined);
    };

    const handleEditOnSave = () => {
        if (!id || !editingNoteId || !casePlanId) {
            return;
        }

        setNoteErrorMessages([]);
        setApiError(undefined);

        if (!updatedNote && editingNoteFiles.length === 0) {
            setNoteErrorMessages(['Note text or a file is required']);
            return;
        }

        setSavingNote(true);

        updatePlanNote({
            noteId: editingNoteId,
            clientId: id,
            casePlanId,
            text: updatedNote,
            files: editingNoteFiles.map(f => f.fileId!)
        }).then(r => {
            setRecentlyChangedNote(editingNoteId);
            setPlan(p => {
                if (!p) {
                    return p;
                }
                const matchingNote = p.notes && p.notes.find(n => n.id === editingNoteId);
                if (!matchingNote) {
                    return p;
                }

                matchingNote.text = r.text;
                matchingNote.updated = r.updated;
                matchingNote.updatedBy = r.updatedBy;
                matchingNote.updatedByFirstName = r.createdByFirstName;
                matchingNote.updatedByLastName = r.updatedByLastName;
                matchingNote.files = r.files;

                return {
                    ...p,
                    notes: [
                        ...p.notes!.map(n => {
                            return {
                                ...n
                            };
                        })
                    ]
                }
            });

            handleEditingOnCancel();
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setSavingNote(false);
        })
    };

    const handleNewOnSave = () => {
        if (!id || !casePlanId) {
            return;
        }

        setNoteErrorMessages([]);

        if (!newNote && newNoteFiles.length === 0) {
            setNoteErrorMessages(['Note text or a file is required']);
            return;
        }

        setSavingNote(true);

        createPlanNote({
            clientId: id,
            casePlanId,
            text: newNote,
            files: newNoteFiles.map(f => f.fileId!)
        }).then(n => {
            setRecentlyChangedNote(n.id);
            setPlan(p => {
                if (!p) {
                    return p;
                }

                return {
                    ...p,
                    notes: !p.notes ? [n] : [
                        ...p.notes,
                        n
                    ]
                };
            });

            handleNewOnCancel();
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setSavingNote(false);
        })
    };

    const handleNewOnCancel = () => {
        setNewNote('');
        setNewNoteFiles([]);
        setAddingNote(false);
    };

    const handleEditingPlan = () => {
        if (!plan) {
            return;
        }

        setEditingPlan(true);
        setNewPlanName(plan.name);
        setNewPlanStartDate((plan.started && formatJSONDateWithoutTime(plan.started, 'yyyy-MM-dd')) || '');
        setModalApiError(undefined);
        setNewPlanNameMessages([]);
    };

    const handleOnConfirmDeletingPlan = () => {
        if (!plan) {
            return;
        }

        setDeletingPlan(false);
        setConfirmDeletingPlan(true);
        setModalApiError(undefined);
    };

    const handleOnDragEnd = (result: any) => {
        const { source, destination, draggableId } = result;
        // make sure there is a destination (picked up and dropped outside range)
        // The droppable location matches the source destination (somehow two droppable locations are enabled at once)
        // The indexes don't match, meaning they changed nothing in the order
        if (!destination ||
            destination.droppableId !== source.droppableId ||
            destination.index === source.index) {
            return;
        }

        if (source.droppableId === `/goals`) {
            // we dragged and dropped a goal
            setPlan(p => {
                if (!p?.goals || p.goals.length === 0) {
                    return p;
                }

                const [startingGoal] = p.goals.splice(result.source.index, 1);
                p.goals.splice(result.destination.index, 0, startingGoal);

                return {
                    ...p,
                    goals: [
                        ...p.goals
                    ]
                };
            });
        } else if (source.droppableId) {
            // we dragged and dropped a task
            setPlan(p => {
                if (!p?.goals || p.goals.length === 0) {
                    return p;
                }

                // /goals/{goalId}/tasks/{taskId}
                const [_empty, _goalLabel, goalId, _taskLabel, taskId] = draggableId.split('/');
                const matchingGoal = p.goals.find(g => g.id === goalId);
                if (!matchingGoal) {
                    return p;
                }

                const [startingTask] = matchingGoal.tasks.splice(source.index, 1);
                matchingGoal.tasks.splice(result.destination.index, 0, startingTask);

                return {
                    ...p,
                    goals: [
                        ...p.goals.map(g => {
                            return {
                                ...g,
                                tasks: [
                                    ...g.tasks || []
                                ]
                            }
                        })
                    ]
                };
            })
        }
    }

    const handleOnConfirmCancelPlan = () => {
        if (!plan || !plan.goals || plan.stopped) {
            return;
        }

        setModalValidationError(undefined);
        setStoppedDate(format(new Date(), 'yyyy-MM-dd'));
        setClosingPlan(false);
        setConfirmClosingPlan(true);
        setModalApiError(undefined);
    };

    const handleOnConfirmReopenPlan = () => {
        if (!plan || !plan.stopped) {
            return;
        }

        setModalValidationError(undefined);
        setReopenDate(format(new Date(), 'yyyy-MM-dd'));
        setReopening(false);
        setConfirmReopening(true);
        setModalApiError(undefined);
    };

    const handleOnGoalChanged = (goal: CasePlanGoal) => {
        const existingGoal = plan?.goals?.find(g => g.id === goal.id);
        if (!existingGoal) {
            return;
        }

        existingGoal.dueDate = goal.dueDate;
        existingGoal.endStatus = goal.endStatus;
        existingGoal.name = goal.name;
        existingGoal.order = goal.order;
        existingGoal.statusDate = goal.statusDate;
        existingGoal.statusId = goal.statusId;
        existingGoal.statusName = goal.statusName;
        existingGoal.tasks = goal.tasks;

        setPlan({
            ...plan!,
            goals: [
                ...plan!.goals!
            ]
        });
    };

    const handleOnReorderingTasks = (goal: CasePlanGoal, reordering: boolean) => {
        if (reordering) {
            if (goalReordering !== goal.id) {
                setGoalReordering(goal.id);
            }
        } else {
            if (goalReordering === goal.id) {
                setGoalReordering(undefined);
            }
        }
    };

    const handleOnGoalKeyUp = (e: KeyboardEvent, goal: CasePlanGoal) => {
        if (!reordering || (e.code !== 'ArrowDown' && e.code !== 'ArrowUp') || !plan?.goals || savingReorder) {
            return;
        }

        if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            e.stopPropagation();

            if (e.code === 'ArrowUp') {
                if (!plan?.goals || plan.goals.length === 0) {
                    return;
                }

                const goalIndex = plan.goals.findIndex(g => g.id === goal.id);
                if (goalIndex === -1 || goalIndex === 0) {
                    return;
                }

                const [startingGoal] = plan.goals.splice(goalIndex, 1);
                plan.goals.splice(goalIndex - 1, 0, startingGoal);

                setPlan({
                    ...plan,
                    goals: [
                        ...plan.goals
                    ]
                });
            } else if (e.key === 'ArrowDown') {
                if (!plan?.goals || plan.goals.length === 0) {
                    return;
                }

                const goalIndex = plan.goals.findIndex(g => g.id === goal.id);
                if (goalIndex === -1 || goalIndex === (plan.goals.length - 1)) {
                    return;
                }

                const [startingGoal] = plan.goals.splice(goalIndex, 1);
                plan.goals.splice(goalIndex + 1, 0, startingGoal);

                setPlan({
                    ...plan,
                    goals: [
                        ...plan.goals
                    ]
                });
            }
        }
    };

    const getMainContent = () => {
        if (loading) {
            return <Loader />
        }

        return <>
            <ApiErrorMessages error={apiError} />
            {plan && (<>
                <div className="row align-items-center justify-content-between">
                    <div className="column-auto">
                        <h3>Plan: {plan.name}</h3>
                        {plan.started && <div>Started: {formatJSONDateWithoutTime(plan.started, 'MM/dd/yyyy')}</div>}
                    </div>
                    <div className="column-auto">
                        <div className="row">
                            <div className="column-auto">
                                <Button id="edit-plan-start"
                                    text="Edit"
                                    onClick={handleEditingPlan}
                                    disabled={stopped}
                                    className="small" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="row align-items-center justify-content-between">
                            <div className="column-auto">
                                <h3>Plan Goals</h3>
                            </div>
                            <div className="column-auto">
                                <div className="row align-items-center">
                                    {savingReorder && <div className="column-auto">
                                        <Loader noMarginTop={true} />
                                    </div>}
                                    <div className="column-auto">
                                        <Button id="toggle-draggable"
                                            text={reordering ? 'All Done' : 'Reorder Goals'}
                                            onClick={() => setReordering(!reordering)}
                                            className="secondary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {reordering && <div className="row justify-content-end">
                            <div className="column-auto">
                                <p className="text-sub" aria-live="polite" aria-atomic="false">
                                    Drag and drop the goals or use Ctrl+shift+up arrow or Ctrl+shift+down arrow
                                </p>
                            </div>
                        </div>}
                        {(!plan.goals || plan.goals.length === 0) && <div>No goals found</div>}
                        {plan.goals && plan.goals.length > 0 && (
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="/goals" isDropDisabled={!reordering}>
                                    {(provided) =>
                                        <div className={getclassNames('list-item-container margin-vertical-3', reordering ? 'reordering' : '')} tabIndex={reordering ? -1 : undefined} {...provided.droppableProps} ref={provided.innerRef}>
                                            {plan.goals && plan.goals.map((g, i) => {
                                                return (<Draggable draggableId={`/goals/${g.id}`} key={g.id} index={i} isDragDisabled={!reordering}>
                                                    {(providedDraggable) =>
                                                    (<div className="list-item"
                                                        onKeyUp={(e) => handleOnGoalKeyUp(e, g)}
                                                        ref={node => {
                                                            providedDraggable.innerRef(node)
                                                            if (i === 0) {
                                                                firstGoalRef.current = node
                                                            }
                                                        }}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <ParticipantCasePlanGoal goal={g}
                                                            casePlanStopped={stopped}
                                                            goalNumber={i + 1}
                                                            clientId={id!}
                                                            onGoalChanged={handleOnGoalChanged}
                                                            onDelete={handleOnDeleteGoal}
                                                            goalStatuses={goalStatuses}
                                                            editing={recentlyCreatedGoal?.id === g.id}
                                                            focused={recentlyCreatedGoal?.id === g.id}
                                                            onReorderingTasks={handleOnReorderingTasks}
                                                            otherGoalReorderingTasks={goalReordering !== undefined && goalReordering !== g.id}
                                                            reorderingGoal={reordering} />
                                                    </div>)
                                                    }

                                                </Draggable>)
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    }
                                </Droppable>
                            </DragDropContext>
                        )}
                        <Button id="add-goal"
                            text="Add Goal"
                            className="margin-top-3 action"
                            onClick={handleOnAddGoal}
                            loading={addingGoal}
                            disabled={addingGoal || reordering || stopped} />
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="column-auto">
                        <Button id="delete-plan-start"
                            text="Delete Plan"
                            onClick={handleOnConfirmDeletingPlan}
                            className="small warning" />
                    </div>
                    {plan.stopped && (<div className="column-auto">
                        <Button id="restart-plan-start"
                            text="Restart Plan"
                            onClick={handleOnConfirmReopenPlan}
                            className="small" />
                    </div>)}
                    {!plan.stopped && (<div className="column-auto">
                        <Button id="stop-plan-start"
                            text="Stop Plan"
                            onClick={handleOnConfirmCancelPlan}
                            className="small" />
                    </div>)}
                </div>
                <div className="row margin-top-3">
                    <div className="column-auto">
                        <Button id={`toggle-notes`}
                            text={showingNotes ? 'Hide Notes' : 'Show Notes'}
                            onClick={() => setShowingNotes(!showingNotes)}
                            className="secondary"
                            iconLocation="end"
                            icon={showingNotes ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />} />
                    </div>
                </div>
                {showingNotes && <div className="row margin-top-3">
                    <div className="column border padding-4 rounded">
                        {!addingNote && <Button id="start-adding-note-button" onClick={() => setAddingNote(true)} text="Add New Note" />}
                        {addingNote && <>
                            <ErrorMessages messages={noteErrorMessages} />
                            <div className="row">
                                <div className="column">
                                    <Input type="multi"
                                        id="new-note-input"
                                        value={newNote}
                                        label="Note"
                                        onChange={setNewNote} />
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column">
                                    <FileUpload id="new-files-upload"
                                        value={newNoteFiles}
                                        onChange={setNewNoteFiles}
                                        multiple={true} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-top-2">
                                <div className="column-auto">
                                    <Button id="cancel-new-note-button"
                                        onClick={handleNewOnCancel}
                                        className="secondary"
                                        text="Cancel"
                                        disabled={savingNote} />
                                </div>
                                <div className="column-auto">
                                    <Button id="new-note-button"
                                        onClick={handleNewOnSave}
                                        text="Create New Note"
                                        className="action"
                                        loading={savingNote}
                                        disabled={savingNote || newNoteFiles.some(f => f.status !== UPLOADED)} />
                                </div>
                            </div>
                        </>}
                        {plan.notes && plan.notes.length !== 0 && <div className="list-item-container margin-top-3">
                            {plan.notes.map(n => {
                                const options = [];

                                options.push({
                                    label: 'Edit',
                                    onClick: () => handleEditingNote(n)
                                });

                                options.push({
                                    label: 'Delete',
                                    onClick: () => handleConfirmDelete(n)
                                });

                                return (<div className={getclassNames('list-item', recentlyChangedNote === n.id ? 'highlight' : '')} key={n.id}>
                                    {editingNoteId !== n.id && <>
                                        <div className="row align-items-top">
                                            <div className="column">
                                                {n.text && <TruncatedText formatted={true}
                                                    value={n.text} maxLength={100}
                                                    lengthVariance={100} id={`${n.id}-note`} />}
                                                {!n.text && <span className="text-background text-italic">&lt;No note to display&gt;</span>}
                                            </div>
                                            <div className="column-auto">
                                                <Dropdown id={`options-dropdown-${n.id}`}
                                                    options={options}
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-between margin-top-5 text-sub">
                                            <div className="column-auto">
                                                {n.updated ? format(new Date(n.updated), 'Pp') : format(new Date(n.created), 'Pp')}
                                            </div>
                                            <div className="column-auto">
                                                {n.updatedByFirstName ? `${n.updatedByFirstName} ${n.updatedByLastName}` : `${n.createdByFirstName} ${n.createdByLastName}`}
                                            </div>
                                        </div>
                                        {n.files && n.files.length !== 0 && <div className="row margin-top-3">
                                            <div className="column">
                                                <FileUpload id={`note-files-${n.id}`}
                                                    value={mapToUploadableFiles(n.files)}
                                                    disableRemove={true}
                                                    disableUpload={true}
                                                    label="Attachments" />
                                            </div>
                                        </div>}
                                    </>}
                                    {editingNoteId === n.id && <>
                                        <ErrorMessages messages={noteErrorMessages} />
                                        <div className="row">
                                            <div className="column">
                                                <Input type="multi"
                                                    id="edit-note-input"
                                                    value={updatedNote}
                                                    label="Note"
                                                    onChange={setUpdatedNote} />
                                            </div>
                                        </div>
                                        <div className="row margin-top-2">
                                            <div className="column">
                                                <FileUpload id="edit-files-upload"
                                                    value={editingNoteFiles}
                                                    onChange={setEditingNoteFiles}
                                                    multiple={true} />
                                            </div>
                                        </div>
                                        <div className="row justify-content-end margin-top-2">
                                            <div className="column-auto">
                                                <Button id="cancel-save-note-button"
                                                    onClick={handleEditingOnCancel}
                                                    className="secondary"
                                                    text="Cancel"
                                                    disabled={savingNote} />
                                            </div>
                                            <div className="column-auto">
                                                <Button id="save-note-button"
                                                    onClick={handleEditOnSave}
                                                    text="Update Note"
                                                    loading={savingNote}
                                                    disabled={savingNote || editingNoteFiles.some(f => f.status !== UPLOADED)} />
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>)
                            })}
                        </div>}
                    </div>
                </div>}
            </>)}
        </>;
    };

    const handleCancelPlanEdit = () => {
        setSavingPlan(false);
        setEditingPlan(false);
        setNewPlanName('');
        setNewPlanStartDate('');
        setModalApiError(undefined);
        setNewPlanNameMessages([]);
    };

    const handleOnSavePlan = (e: FormEvent) => {
        e.preventDefault();

        if (!id || !casePlanId) {
            return;
        }

        let valid = true;
        if (!newPlanName) {
            setNewPlanNameMessages([
                'Plan name is required'
            ]);
            valid = false;
        }

        if (!valid) {
            return;
        }

        setSavingPlan(true);

        updateCasePlan({
            started: newPlanStartDate,
            clientId: id,
            casePlanId,
            name: newPlanName
        }).then(r => {
            setPlan(p => {
                if (!p) {
                    return p;
                }

                return {
                    ...p,
                    updated: r.updated,
                    updatedBy: r.updatedBy,
                    name: r.name,
                    started: r.started
                }
            });
            handleCancelPlanEdit();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setSavingPlan(false);
        })
    };

    const handleOnPlanDelete = () => {
        if (!id || !casePlanId) {
            return;
        }

        setDeletingPlan(true);

        deleteCasePlan({
            clientId: id,
            id: casePlanId
        }).then(() => {
            navigate(-1);
        }).catch(e => {
            setModalApiError(e);
            console.error(e);
        }).finally(() => {
            setDeletingPlan(false);
        });
    };

    const handleCancelPlanDelete = () => {
        setModalApiError(undefined);
        setDeletingPlan(false);
        setConfirmDeletingPlan(false);
    };

    const handleCancellingClose = () => {
        setModalApiError(undefined);
        setClosingPlan(false);
        setConfirmClosingPlan(false);
        setModalValidationError(undefined);
        setStoppedDate('');
    };

    const handleOnClosePlan = () => {
        if (!id || !casePlanId) {
            return;
        }

        if (!stoppedDate) {
            setModalValidationError('Closed On is required');
            return;
        }

        setClosingPlan(true);

        stopCasePlan({
            clientId: id,
            casePlanId,
            stopped: stoppedDate
        }).then(() => {
            setPlan(p => {
                if (!p) {
                    return p;
                }

                p.stopped = stoppedDate;

                const openGoals = p.goals && p.goals.filter(g => !g.endStatus);
                if (openGoals && openGoals.length !== 0) {
                    for (const goal of openGoals) {
                        goal.endStatus = true;
                        goal.updated = new Date().toISOString();
                        goal.updatedBy = p.updatedBy;
                        goal.statusName = 'Skipped';
                        goal.statusId = '2'; // 2 is skipped...I don't like this
                        goal.statusDate = new Date().toISOString(); // these should be the same
                    }
                }

                return {
                    ...p,
                    goals: (p.goals && [
                        ...p.goals.map(g => {
                            return {
                                ...g
                            }
                        })
                    ]) || []
                };
            });
            handleCancellingClose();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setClosingPlan(false);
        });
    };


    return (<section id="main-content" tabIndex={-1}>
        <BackButton />
        {getMainContent()}
        <Modal id="delete-plan-modal" visible={confirmDeletingPlan} className="small" onClose={handleCancelPlanDelete} title="Confirm Delete Plan">
            <div>
                {modalApiError && <ApiErrorMessages error={modalApiError} />}
                <div className="row">
                    <div className="column">
                        Are you sure you want to delete this plan?
                    </div>
                </div>
                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-delete-plan"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={deletingPlan}
                                    onClick={handleCancelPlanDelete} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="confirm-delete-goal"
                                    text="Delete Plan"
                                    className="warning"
                                    disabled={deletingPlan}
                                    loading={deletingPlan}
                                    onClick={handleOnPlanDelete} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal id="delete-goal-modal" visible={deletingGoal !== undefined} className="small" onClose={handleDeleteCancel} title="Confirm Delete Goal">
            {deletingGoal && (
                <div>
                    {modalApiError && <ApiErrorMessages error={modalApiError} />}
                    <div className="row">
                        <div className="column">
                            Are you sure you want to delete this goal?
                            <br />
                            {deletingGoal.name}
                            <br />
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-delete-goal"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={deleting}
                                        onClick={handleDeleteCancel} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-delete-goal"
                                        text="Delete Goal"
                                        className="warning"
                                        disabled={deleting}
                                        loading={deleting}
                                        onClick={handleOnDelete} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
        <Modal id="edit-plan-modal" visible={editingPlan} className="small" onClose={handleCancelPlanEdit} title="Edit Plan">
            <form onSubmit={handleOnSavePlan}>
                {modalApiError && <ApiErrorMessages error={modalApiError} />}
                <div className="row">
                    <div className="column">
                        <ValidatedInput id="edit-plan-name"
                            value={newPlanName}
                            onChange={setNewPlanName}
                            label="Plan Name"
                            messages={newPlanNameMessages}
                            required={true} />

                    </div>
                </div>
                <div className="row margin-top-3">
                    <div className="column">
                        <Input id="edit-plan-start-date"
                            value={newPlanStartDate}
                            onChange={setNewPlanStartDate}
                            type="date"
                            label="Start Date" />
                    </div>
                </div>

                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-plan-edit"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={savingPlan}
                                    onClick={handleCancelPlanEdit} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="confirm-plan-edit"
                                    text="Save"
                                    className="action"
                                    disabled={savingPlan}
                                    loading={savingPlan}
                                    type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
        <Modal id="delete-note-modal" visible={deletingNote !== undefined} className="small" onClose={handleCancellingDelete} title="Confirm Delete Note">
            {deletingNote && (
                <div>
                    {modalApiError && <ApiErrorMessages error={modalApiError} />}
                    <div className="row">
                        <div className="column">
                            Are you sure you want to delete this note?
                            <br />
                            <br />
                            <TruncatedText id={`delete-note-${deletingNote.id}`}
                                formatted={true}
                                lengthVariance={100}
                                value={deletingNote.text}
                                maxLength={50} />
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-delete-note"
                                        text="Cancel"
                                        className="secondary"
                                        disabled={executingDeleteNote}
                                        onClick={handleCancellingDelete} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-delete-note"
                                        text="Delete Note"
                                        className="warning"
                                        disabled={deleting}
                                        loading={deleting}
                                        onClick={handleOnDeleteNote} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
        <Modal id="stop-plan-modal" visible={confirmClosingPlan} className="small" onClose={handleCancellingClose} title="Stop Plan">
            {plan && <div>
                <ApiErrorMessages error={modalApiError} />
                <ErrorMessages messages={[modalValidationError || '']} />
                <div>Are you sure you want to stop this plan?</div>
                <div>The following goals will be moved to Skipped in order to stop this plan.</div>
                <div className="margin-top-5">
                    <Input id="stopped-date"
                        label="Plan Stop Date"
                        type="date"
                        value={stoppedDate || format(new Date(), 'yyyy-MM-dd')}
                        onChange={setStoppedDate} />
                </div>
                {plan.goals && <ol className="margin-top-5">
                    {plan.goals.map((g, i) => {
                        if (g.endStatus) {
                            return null;
                        }
                        return (<div className="margin-start-4 margin-top-4 text-bold">{i + 1}. {
                            !g.name ?
                                <i>&lt;No goal entered&gt;</i> :
                                <TruncatedText id={`goal-text-${g.id}`}
                                    value={g.name}
                                    maxLength={100}
                                    lengthVariance={10} />}
                        </div>)
                    })}
                </ol>}
                <br />
                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-stop-plan"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={closingPlan}
                                    onClick={handleCancellingClose} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="confirm-stop-plan"
                                    text="Stop Plan"
                                    className="action"
                                    disabled={closingPlan}
                                    loading={closingPlan}
                                    onClick={handleOnClosePlan} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Modal>
        <Modal id="restart-plan-modal" visible={confirmReopening} className="small" onClose={handleCancellingReopen} title="Restart Plan">
            <form onSubmit={handleOnReopen}>
                {modalApiError && <ApiErrorMessages error={modalApiError} />}
                <ErrorMessages messages={[modalValidationError || '']} />
                <div>
                    <Input id="restart-date"
                        label="Plan Restart Date"
                        type="date"
                        value={reopenDate || format(new Date(), 'yyyy-MM-dd')}
                        onChange={setReopenDate} />
                </div>
                <div className="margin-top-5">Are you sure you want to restart this plan?</div>
                <br />
                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-restart-plan"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={reopening}
                                    onClick={handleCancellingReopen} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="confirm-restart-plan"
                                    text="Restart Plan"
                                    className="action"
                                    disabled={reopening}
                                    loading={reopening}
                                    type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </section>);
};

export default ParticipantCasePlan;