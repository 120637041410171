import { useState, useEffect, useRef, useContext } from "react";
import FileUpload from "./FileUpload";
import UploadableFile from "../../models/pageModels/UploadableFile";
import ClientFile from "../../models/serviceModels/ClientFile";
import { getClientFiles, upsertClientFiles } from "../../services/fileService";
import ApiErrorMessages from "./ApiErrorMessages";
import Loader from "./Loader";

interface ParticipantFilesProps {
    clientId: string;
}

const ParticipantFiles = (props: ParticipantFilesProps) => {
    const [files, setFiles] = useState<ClientFile[]>([]);
    const [apiError, setApiError] = useState<any>();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const loadedRef = useRef(false);

    useEffect(() => {
        setLoading(true);
        getClientFiles(props.clientId).then(result => {
            setFiles([
                ...result.map(r => {
                    return {
                        ...r
                    } as ClientFile;
                })
            ]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
            loadedRef.current = true;
        });
    }, []);

    const handleOnChange = (changedFiles: UploadableFile[]) => {
        if ((changedFiles.length !== files.length || changedFiles.some(cf => !files.find(f => f.fileId === cf.fileId))) && changedFiles.every(cf => cf.fileId)) {
            setUploading(true);
            upsertClientFiles({
                files: changedFiles.map(nf => nf.fileId!),
                clientId: props.clientId
            }).then(result => {
                const newFiles: ClientFile[] = [];
                for (const fileResult of result) {
                    const matchingFile = files.find(f => f.fileId === fileResult.fileId);
                    if (matchingFile) {
                        matchingFile.id = fileResult.id;
                    } else {
                        newFiles.push({
                            id: fileResult.id,
                            created: fileResult.created,
                            fileId: fileResult.fileId,
                            name: fileResult.name,
                            status: fileResult.status
                        });
                    }
                }

                setFiles([
                    ...files,
                    ...newFiles
                ]);
            }).catch(e => {
                console.error(e);
                setApiError(e);
            }).finally(() => {
                setUploading(false);
            });
        }
    };

    if (loading) {
        return (<div className="row justify-content-between">
            <div className="column-auto">
                <h2>Participant Files</h2>
            </div>
            <div className="column-auto">
                <Loader />
            </div>
        </div>);
    }

    return (<>
        <div className="row justify-content-between">
            <div className="column-auto">
                <h2>Participant Files</h2>
            </div>
            {uploading && <div className="column-auto">
                <Loader />
            </div>}
        </div>
        <ApiErrorMessages error={apiError} />
        <FileUpload id="participant-files" 
            multiple={true} 
            value={files} 
            onChange={handleOnChange} />
    </>);
};

export default ParticipantFiles;