const PREMIUM_PLAN_TYPE_ID = 2;
const STANDARD_PLAN_TYPE_ID = 1;
const TRIAL_PLAN_TYPE_ID = 3;
const FREE_PLAN_TYPE_ID = 5;

export {
    PREMIUM_PLAN_TYPE_ID,
    STANDARD_PLAN_TYPE_ID,
    TRIAL_PLAN_TYPE_ID,
    FREE_PLAN_TYPE_ID
};